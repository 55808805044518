<template>
    <div
        id="icons"
        class="ml-auto d-flex"
    >
        <TheHeaderIconBarHelpButton />
        <v-btn
            v-for="(x, index) in icon_bar"
            :key="index"
            @click="open_right_navigation_drawer(x)"
            icon
        >
            <v-icon color="white">
                {{ x.icon }}
            </v-icon>
        </v-btn>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
import TheHeaderIconBarHelpButton from '@/components/the_header/icon_bar/HelpButton.vue'

export default {
    name: 'TheHeaderIconBar',
    components: { TheHeaderIconBarHelpButton },
    mixins: [common_mixin],
    props: {},
    data() {
        return {
            navigation_drawer: false,
            selected_menu: undefined,
            shortcut_code: '',
            init_search_tab: undefined,
        }
    },
    computed: {
        icon_bar() {
            return [
                {
                    component: 'SearchMenu',
                    icon: 'mdi-magnify',
                },
                {
                    component: 'WatchList',
                    icon: 'mdi-star',
                },
                {
                    component: 'SettingsContainer',
                    icon: 'mdi-cog',
                },
            ]
        },
        ...mapGetters(['search_menu']),
        ...mapState({
            user_extended_data: (state) => state.user_extended_data,
        }),
    },
    watch: {},
    methods: {
        open_right_navigation_drawer(icon_bar_config) {
            this.$emit('open_right_navigation_drawer')
            this.$emit('right_navigation_component', icon_bar_config.component)
        },
        keyboard_pressed(e) {
            let new_letter = String.fromCharCode(e.keyCode)
            this.shortcut_code += new_letter
            let valid_non_complete_shortcut_attempt =
                (this.shortcut_code.startsWith('+') || this.shortcut_code.startsWith('++')) &&
                this.shortcut_code.length < 4
            if (valid_non_complete_shortcut_attempt) return

            let menu_index = this.search_menu.map((k) => k['shortcut_code']).indexOf(this.shortcut_code)
            if (menu_index > -1) {
                this.set_state_property({
                    state_property: 'right_navigation_search_init_tab',
                    data: menu_index,
                })
                this.open_right_navigation_drawer({ component: 'Search' })
            }
            this.shortcut_code = ''
        },
        ...mapActions(['set_state_property', 'set_state_properties']),
    },
    beforeCreate() {},
    created() {
        window.addEventListener('keypress', this.keyboard_pressed)
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener('keypress', this.keyboard_pressed)
    },
}
</script>

<style lang="sass" scoped>
::v-deep .v-badge__badge
    pointer-events: none
</style>
