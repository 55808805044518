import { mapActions, mapGetters, mapState } from 'vuex'
import local_storage_mixin from '@/mixins/common/local_storage_mixin'
import VueJwtDecode from 'vue-jwt-decode'

export const multi_tab_state_mixin = {
    mixins: [local_storage_mixin],
    data() {
        return {}
    },
    computed: {
        ...mapGetters({}),
        ...mapState({}),
    },
    methods: {
        update_multi_tab_data(key, value) {
            this.set_state_property({
                state_property: key,
                data: value,
            })
            this.set_local_storage(key, value)
        },
        load_multi_tab_data() {
            const payloads = [
                {
                    state_property: 'user_tokens',
                    data: this.get_local_storage('user_tokens'),
                },
                {
                    state_property: 'user_settings',
                    data: this.get_local_storage('user_settings') || {},
                },
            ]
            this.set_state_properties(payloads)
        },
        handle_tab_synchronization({ key, newValue, oldValue }) {
            // Add methods if a key requires specific handling
            const methods = {
                user_tokens: this.handle_user_tokens,
                user_settings: this.handle_generic_update,
            }

            let method = methods[key]
            if (!method) return

            console.log('syncing: ', key)

            let new_value = JSON.parse(newValue)
            let old_value = JSON.parse(oldValue)

            method(key, new_value, old_value)
        },
        // eslint-disable-next-line no-unused-vars
        handle_user_tokens(key, new_value, old_value) {
            let save_token = false

            if (new_value && new_value.selected_token === this.user_tokens?.selected_token) {
                const new_token_json = VueJwtDecode.decode(new_value.token_list[new_value.selected_token].token)
                const old_token_json = VueJwtDecode.decode(
                    this.user_tokens.token_list[this.user_tokens.selected_token].token
                )

                save_token = new_token_json.refresh_count > old_token_json.refresh_count
            } else if (new_value?.selected_token !== this.user_tokens?.selected_token) {
                save_token = true
            }

            if (save_token) {
                this.set_state_property({
                    state_property: 'user_tokens',
                    data: new_value,
                })
            }
        },
        // eslint-disable-next-line no-unused-vars
        handle_generic_update(key, new_value, old_value) {
            if (new_value === old_value) {
                return
            }

            this.set_state_property({
                state_property: key,
                data: new_value,
            })
        },

        ...mapActions({
            set_state_properties: 'set_state_properties',
            set_state_property: 'set_state_property',
        }),
    },
}
