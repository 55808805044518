<template>
    <v-scroll-y-transition>
        <section
            v-show="visible"
            class="px-6"
        >
            <div class="py-4 px-2">
                <div class="mb-2 subtitle-2 font-weight-medium app-text--overflow-hidden-ellipsis">
                    {{ event(item) }}
                </div>
                <div
                    class="caption app-opacity--level-6 d-flex align-center"
                    style="gap: 8px"
                >
                    <div class="text-uppercase font-weight-medium">
                        {{ item['creditor_name'] }}
                    </div>
                    <div class="font-weight-medium">
                        |
                    </div>
                    <div>{{ format_date(item['date']) }}</div>
                </div>
            </div>
            <v-divider />
        </section>
    </v-scroll-y-transition>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import locale_mixin from '@/mixins/common/locale_mixin'

export default {
    name: 'TheDashboardEventsListItem',
    props: {
        item: {
            type: Object,
        },
        index: {
            type: Number,
        },
    },
    mixins: [locale_mixin],
    components: {},
    data() {
        return {
            visible: false,
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        event(item) {
            const suffix = item['value'] === 1 ? '' : '_multiple'
            return String.format(this.translate(`common.start_page_events_${item['key']}${suffix}`), item['value'])
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        const max_timeout = 400
        const timeout = Math.min(this.index * 100, max_timeout)
        setTimeout(() => {
            this.visible = true
            if (timeout === max_timeout) this.$emit('completed')
        }, timeout)
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
