import { mapActions, mapGetters, mapState } from 'vuex'
import api_mixin from '@/mixins/common/api_mixin'
import local_storage_mixin from '@/mixins/common/local_storage_mixin'
import navigation_mixin from '@/mixins/common/navigation_mixin'
import token_mixin from '@/mixins/common/token_mixin'
import date_mixin from '@/mixins/common/date_mixin'
import locale_mixin from '@/mixins/common/locale_mixin'
import utils_mixin from '@/mixins/common/utils_mixin'
import user_mixin from '@/mixins/common/user_mixin'
import user_settings_mixin from '@/mixins/common/user_settings_mixin'
import object_mixin from '@/mixins/common/object_mixin'
import access_mixin from '@/mixins/common/access_mixin'
import Vue from 'vue'

export default {
    mixins: [
        access_mixin,
        api_mixin,
        date_mixin,
        local_storage_mixin,
        navigation_mixin,
        object_mixin,
        token_mixin,
        locale_mixin,
        utils_mixin,
        user_mixin,
        user_settings_mixin,
    ],
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['user_is_authenticated']),
        ...mapState(['application_details', 'user_login_date']),
    },
    methods: {
        touch_is_enabled() {
            return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
        },
        responsive_width(xs, s, md, lg, xl) {
            return this.$vuetify.breakpoint.xsOnly
                ? xs
                : this.$vuetify.breakpoint.smOnly
                ? s
                : this.$vuetify.breakpoint.mdOnly
                ? md
                : this.$vuetify.breakpoint.lgOnly
                ? lg
                : xl
        },
        async get_application_details() {
            const request = await fetch(`${process.env.VUE_APP_API_ROOT}/application--details/ada_ui`)
            const new_application_details = await request.json()

            const storage_cleanup =
                this.user_login_date &&
                new_application_details['storage_cleanup_date'] &&
                this.format_date_time(new_application_details['storage_cleanup_date']) > this.user_login_date

            // TODO: verify if the code below is correct
            // if (
            //     storage_cleanup_date > this.user_login_date ||
            //     (this.user_is_authenticated && storage_cleanup_date && this.user_login_date === null)
            // ) {
            //     console.log('<<< cleanup storage >>>')
            //     await localStorage.clear()
            //     this.reset_cache()
            //     return window.location.reload()
            // }
            // if(storage_cleanup_date > this.selected_token_json.exp)

            const current_application_version = this.application_details?.['application_version']
            const current_api_version = this.application_details?.['api_version']
            let new_application_version =
                current_application_version &&
                this.format_date_time(new_application_details['application_version']) >
                    this.format_date_time(current_application_version)
            let new_api_version =
                current_api_version &&
                this.format_date_time(new_application_details['api_version']) >
                    this.format_date_time(current_api_version)

            this.set_state_property({
                state_property: 'application_details',
                data: new_application_details,
            })
            if (new_api_version || storage_cleanup) {
                console.log('<<<New API version => Reset swagger>>>')
                this.set_state_properties([
                    {
                        module: 'swagger',
                        state_property: 'schemas',
                        data: {},
                    },
                    {
                        module: 'swagger',
                        state_property: 'paths',
                        data: {},
                    },
                    {
                        module: 'swagger',
                        state_property: 'x_data',
                        data: {},
                    },
                ])
            }
            if (new_application_version || new_api_version) {
                this.reset_cache()
            }
        },
        reset_cache() {
            caches.keys().then((cache_keys) => {
                console.log('cache_keys', cache_keys)
                cache_keys.forEach((key) => {
                    caches.delete(key)
                })
                // window.location.reload()
            })
        },
        scroll_to_ref(ref) {
            if (!ref) {
                return
            }
            this.$nextTick(() => {
                let top = ref.offsetTop
                window.scrollTo({
                    top: top,
                    behavior: 'smooth',
                })
            })
        },
        focus_ref(ref, focusout = false) {
            let reference = this.$refs[ref]
            if (Array.isArray(reference) && reference.length) {
                reference = reference[0]
            }
            if (reference) {
                if (focusout) {
                    reference.blur()
                } else {
                    reference.focus()
                }
            }
        },
        set_nested_property(obj, dotted_path, value) {
            const keys = dotted_path.split('.')
            const key = keys.at(0)
            const next_key_is_array_index = !isNaN(keys.at(1))

            if (keys.length === 1) {
                return Vue.set(obj, key, value)
            }

            if (!obj[key]) {
                if (next_key_is_array_index) {
                    Vue.set(obj, key, [])
                } else {
                    Vue.set(obj, key, {})
                }
            }

            this.set_nested_property(obj[key], keys.slice(1).join('.'), value)
        },
        console_log(msg) {
            console.log(msg)
        },
        ...mapActions(['set_state_properties']),
    },
}
