<template>
    <section :class="{ dashboard_grid: show }">
        <v-expand-transition>
            <TheDashboardTodo
                v-if="show"
                :creditor_id="creditor_id"
            />
        </v-expand-transition>
        <v-expand-transition>
            <TheDashboardEvents
                v-if="show"
                :creditor_id="creditor_id"
            />
        </v-expand-transition>
        <TheDashboardStatistics
            :creditor_id="creditor_id"
            :show="show"
        />
    </section>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import TheDashboardTodo from '@/components/the_dashboard/todo/index.vue'
import TheDashboardEvents from '@/components/the_dashboard/events/index.vue'
import TheDashboardStatistics from '@/components/the_dashboard/statistics/index.vue'

export default {
    name: 'TheDashboard',
    props: {
        creditor_id: {
            type: String,
        },
    },
    mixins: [],
    components: {
        TheDashboardStatistics,
        TheDashboardEvents,
        TheDashboardTodo,
    },
    data() {
        return {}
    },
    computed: {
        show() {
            return Boolean(!this.solicitor || this.creditor_id)
        },
        ...mapGetters(['solicitor']),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style lang="scss" scoped>
.dashboard_grid {
    display: grid;
    grid-template-areas:
        'todo events'
        'statistics statistics';
    grid-template-columns: calc(60% - 8px) calc(40% - 8px);
    gap: 40px 16px;
    @media only screen and (max-width: 900px) {
        grid-template-areas:
            'todo todo'
            'events events'
            'statistics statistics';
    }
}
</style>
