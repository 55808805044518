<template>
    <section
        v-if="!items.length"
        class="flex-grow-1 d-flex align-center justify-center"
    >
        <v-progress-circular
            v-if="loading"
            color="brand_primary"
            indeterminate
        />
        <v-fade-transition>
            <div
                v-show="!loading"
                class="subtitle-2 font-weight-medium app-opacity--level-5"
            >
                {{ translate('common.todo_empty_state') }}
            </div>
        </v-fade-transition>
    </section>
    <section
        v-else
        ref="list"
        :class="animation_completed ? 'overflow-auto' : 'overflow-hidden'"
        class="flex-grow-1"
    >
        <TheDashboardTodoListItem
            v-for="(item, index) in items"
            :key="`${selected_filter}${index}`"
            :index="index"
            :item="item"
            @completed="animation_completed = true"
        />
        <div
            v-if="animation_completed && show_more_available"
            class="px-6 pb-4"
        >
            <v-divider class="mb-4" />
            <div class="d-flex justify-center">
                <v-btn
                    @click="$emit('load_more')"
                    outlined
                >
                    {{ translate('common.show_more') }}
                </v-btn>
            </div>
        </div>
    </section>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import locale_mixin from '@/mixins/common/locale_mixin'
import TheDashboardTodoListItem from '@/components/the_dashboard/todo/TodoListItem.vue'

export default {
    name: 'TheDashboardTodoList',
    props: {
        filters: {
            type: Array,
        },
        loading: {
            type: Boolean,
        },
        selected_filter: {
            type: Number,
        },
        show_more_available: {
            type: Boolean,
        },
    },
    mixins: [locale_mixin],
    components: { TheDashboardTodoListItem },
    data() {
        return {
            animation_completed: false,
        }
    },
    computed: {
        items() {
            return this.filters[this.selected_filter].data
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        selected_filter() {
            this.animation_completed = false
            if (this.$refs.list) {
                this.$refs.list.scroll({ top: 0 })
            }
        },
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
