<template>
    <v-avatar
        v-if="!loading"
        :color="avatar_color(value)"
        :size="size"
        class="white--text font-weight-bold"
    >
        <slot name="default">
            <span
                :style="{ 'font-size': `${size / 2}px` }"
                class="app-text--no-select"
            >
                {{ initials(value) }}
            </span>
        </slot>
    </v-avatar>
    <v-progress-circular
        v-else
        :color="avatar_color(value)"
        :size="size"
        indeterminate
    />
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'AppAvatar',
    components: {},
    mixins: [],
    props: {
        value: {
            type: String,
        },
        size: {
            type: String,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        initials(name_or_email) {
            if (!name_or_email) return ''
            const is_email = name_or_email.includes('@')

            let seperator = ' '
            if (is_email) {
                name_or_email = name_or_email.split('@')[0]
            }

            const additional_separators = ['.', '-', '_'].filter((x) => name_or_email.includes(x))
            if (additional_separators.length) {
                seperator = additional_separators[0]
            }

            const [first_name, last_name] = name_or_email.split(seperator)

            let initials = first_name.charAt(0)
            if (last_name) initials += last_name.charAt(0)

            return initials.toUpperCase()
        },
        avatar_color(name_or_email) {
            const colors = Object.keys(this.$vuetify.theme.currentTheme).filter((v) => {
                return v.includes('main') && !v.includes('white')
            })

            let char_sum = 0
            for (const index in name_or_email) {
                char_sum += name_or_email.charCodeAt(index)
            }
            return colors[char_sum % colors.length]
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
