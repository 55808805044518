import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    data() {
        return {
            right_to_left_locales: ['ar', 'arc', 'dv', 'fa', 'ha', 'he', 'khw', 'ks', 'ku', 'ps', 'ur', 'yi'],
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState(['user_settings', 'user_settings_updates']),
    },
    watch: {},
    methods: {
        update_user_setting(dotted_path, value) {
            dotted_path = dotted_path.startsWith('settings') ? dotted_path : `settings.${dotted_path}`
            this.push_state_property({
                state_property: 'user_settings_updates',
                data: { dotted_path, value },
            })

            this.deep_set(this.user_settings, dotted_path, value)
            this.set_state_property({
                state_property: 'user_settings',
                data: this.user_settings,
            })
        },
        async save_user_settings_updates(updates = {}, handle_unprocessable_entities = true) {
            const tmp_updates = {}
            for (const update of this.user_settings_updates) {
                tmp_updates[update.dotted_path] = update.value
            }
            this.set_state_property({
                state_property: 'user_settings_updates',
                data: [],
            })

            for (const [dotted_path, value] of Object.entries(tmp_updates)) {
                this.deep_set(updates, dotted_path, value)
            }
            try {
                let response = await this.api_patch({
                    url: `/user--settings/${this.user_settings._id}`,
                    data: updates,
                    if_match: this.user_settings._etag,
                })
                this.deep_set(this.user_settings, '_etag', response.data._etag)
                this.deep_set(this.user_settings, '_updated', response.data._updated)
                console.log('<<< User Settings Updated >>>')
                return true
            } catch (e) {
                if (e.status === 422 && handle_unprocessable_entities) {
                    console.error(e)
                    this.recursive_clear_unprocessable_entities_from_updates(updates, e.data._issues)
                    const success = await this.save_user_settings_updates(updates, false)
                    if (success) {
                        await this.get_user_settings(true) // otherwise it was refreshed by error handling
                    }
                } else {
                    await this.get_user_settings(true)
                }
                return false
            }
        },

        set_theme(theme) {
            let available_themes = Object.keys(this.$vuetify.theme.themes)
            available_themes
                .filter((x) => x !== 'light')
                .forEach((k) => {
                    this.$vuetify.theme[k] = false
                })

            if (theme === 'light') return // light theme is when all other themes are disabled

            this.$vuetify.theme[theme] = true
        },
        set_language(locale) {
            this.$i18n.locale = locale
            this.$vuetify.rtl = this.right_to_left_locales.includes(locale)
        },
        recursive_clear_unprocessable_entities_from_updates(updates, issues, inside_array = false) {
            for (const [k, issue] of Object.entries(issues)) {
                const is_array_index = !isNaN(Number(k))

                let delete_key = typeof issue === 'string'
                if (!delete_key && typeof issue === 'object') {
                    delete_key = this.recursive_clear_unprocessable_entities_from_updates(
                        updates[k],
                        issue,
                        is_array_index
                    )
                }

                if (delete_key) {
                    if (inside_array) {
                        return delete_key
                    } else if (is_array_index) {
                        updates.splice(k, 1)
                    } else {
                        delete updates[k]
                    }
                }
            }
            return false
        },
        remove_unallowed_property(unallowed_property_path, value) {
            const path_filters = unallowed_property_path.split('.filters.').at(-1)
            const [index_filter, filter_property, index_property] = path_filters.split('.')
            value[index_filter][filter_property].splice(index_property, 1)
        },
        set_sort_to_default(unallowed_property_path, value) {
            const path_filters = unallowed_property_path.split('.filters.').at(-1)
            const [index_filter, filter_property, property] = path_filters.split('.')
            value[index_filter][filter_property][property] = this.ui_structure[this.resource]['sort'][0]
        },

        ...mapActions(['set_state_property', 'push_state_property']),
    },
}
