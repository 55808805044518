<template>
    <v-tooltip
        color="#0A0D0D"
        bottom
    >
        <template #activator="{ on, attrs }">
            <v-fade-transition>
                <div
                    v-show="!loading"
                    v-bind="attrs"
                    v-on="on"
                >
                    <TheDashboardStatisticsTrendChip :percent="percent" />
                </div>
            </v-fade-transition>
        </template>
        <section
            class="py-2"
            style="width: 270px"
        >
            <div class="d-flex justify-space-between mb-2">
                {{ translate('common.last_thirty_days') }}
                <TheDashboardStatisticsTrendChip :percent="percent" />
            </div>
            <div class="count">
                <div class="number mr-2">
                    {{ new Intl.NumberFormat(current_language).format(last_thirty_days) }}
                </div>
                <div
                    v-if="current_language === 'sv'"
                    class="st"
                >
                    st
                </div>
            </div>
            <v-divider
                class="my-2"
                dark
            />
            <div class="d-flex justify-space-between">
                {{ translate('common.previous_thirty_days') }}
                <div class="d-flex font-weight-medium">
                    {{ previous_thirty_days }} {{ current_language === 'sv' ? ' st' : '' }}
                </div>
            </div>
        </section>
    </v-tooltip>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import TheDashboardStatisticsTrendChip from './Chip.vue'
import { item_mixin } from '@/mixins/item'

export default {
    name: 'TheDashboardStatisticsTrend',
    props: {
        last_thirty_days: {
            type: Number,
        },
        previous_thirty_days: {
            type: Number,
        },
    },
    mixins: [item_mixin],
    components: { TheDashboardStatisticsTrendChip },
    data() {
        return {}
    },
    computed: {
        percent() {
            if (!this.last_thirty_days || !this.previous_thirty_days) return 0
            return Math.round((this.last_thirty_days / this.previous_thirty_days - 1) * 100)
        },
        loading() {
            return this.last_thirty_days === null && this.previous_thirty_days === null
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style lang="scss" scoped>
.count {
    display: flex;
    align-items: baseline;

    div.number {
        line-height: 32px;
        font-weight: 500;
        font-size: 32px;
    }

    div.st {
        font-weight: 500;
        font-size: 16px;
    }
}
</style>
