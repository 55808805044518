import { mapActions, mapGetters, mapState } from 'vuex'
import token_mixin from '@/mixins/common/token_mixin'
import local_storage_mixin from '@/mixins/common/local_storage_mixin'

export default {
    mixins: [token_mixin, local_storage_mixin],
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            selected_token_json: 'selected_token_json',
            user_is_authenticated: 'user_is_authenticated',
        }),
        ...mapState({
            user_tokens: (state) => state.user_tokens,
        }),
    },
    methods: {
        async api_get(payload) {
            await this.pre_api_call(payload)
            const url = this.api_url_and_params(payload)
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'X-API-Key': payload.token || (await this.get_token_from_local_storage()),
                    'Cache-Control': 'no-cache',
                },
            })
            response['data'] = await response.json()
            if (!response.ok) {
                this.general_error_handling(response)
                throw response
            }
            if (payload.commit) {
                payload['data'] = response['data']
                this.set_state_property(payload)
            }
            return response
        },
        async api_post(payload) {
            await this.pre_api_call(payload)
            const url = this.api_url_and_params(payload)
            const headers = {
                'X-API-Key': payload.token || (await this.get_token_from_local_storage()),
            }
            const json = !(payload.data instanceof FormData)
            if (json) {
                headers['Content-Type'] = 'application/json'
            }
            const response = await fetch(url, {
                method: 'POST',
                body: json ? JSON.stringify(payload.data) : payload.data,
                headers: headers,
            })
            response['data'] = await response.json()
            if (!response.ok) {
                this.general_error_handling(response)
                throw response
            }
            return response
        },
        async api_delete(payload) {
            await this.pre_api_call(payload)
            const url = this.api_url_and_params(payload)
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'X-API-Key': payload.token || (await this.get_token_from_local_storage()),
                    'If-Match': payload['if_match'],
                },
            })
            if (!response.ok) {
                response['data'] = await response.json()
                this.general_error_handling(response)
                throw response
            }
            return response
        },
        async api_patch(payload) {
            await this.pre_api_call(payload)
            const url = this.api_url_and_params(payload)
            const response = await fetch(url, {
                method: 'PATCH',
                body: JSON.stringify(payload.data),
                headers: {
                    'X-API-Key': payload.token || (await this.get_token_from_local_storage()),
                    'Content-Type': 'application/json',
                    'If-Match': payload.if_match,
                },
            })
            response['data'] = await response.json()
            if (!response.ok) {
                this.general_error_handling(response)
                throw response
            }
            return response
        },
        async api_put(payload) {
            await this.pre_api_call(payload)
            const url = this.api_url_and_params(payload)
            const response = await fetch(url, {
                method: 'PUT',
                body: JSON.stringify(payload.data),
                headers: {
                    'X-API-Key': payload.token || (await this.get_token_from_local_storage()),
                    'Content-Type': 'application/json',
                    'If-Match': payload.if_match,
                },
            })
            response['data'] = await response.json()
            if (!response.ok) {
                this.general_error_handling(response)
                throw response
            }
            return response
        },
        async pre_api_call(payload) {
            if (!payload.url.startsWith('/')) {
                payload.url = `/${payload.url}`
            }

            if (payload.url === '/authenticates/refresh-token') return // We do not prohibit refreshes
            if (await this.token_exist_and_must_be_refreshed(30)) {
                this.set_state_property({
                    state_property: 'token_has_expired',
                    data: true,
                })

                let total_millis_waited = 0
                while (await this.token_exist_and_must_be_refreshed(30)) {
                    await this.sleep(500)
                    total_millis_waited += 500
                    if (total_millis_waited >= 1000 * 20) {
                        break
                    }
                }
            }
        },
        general_error_handling(error) {
            if (error === undefined) return
            if (error.status === 401) {
                this.sign_out()
                console.error('Unauthorized', error)
                // this.sign_out('unauthorized')
            } else if (error.status === 419) {
                this.sign_out()
            }
        },

        async sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms))
        },
        api_url_and_params(payload) {
            const params = new URLSearchParams()
            for (const key in payload.params) {
                if (typeof payload.params[key] === 'object' && payload.params[key] !== null) {
                    params.append(key, JSON.stringify(payload.params[key]))
                } else {
                    params.append(key, payload.params[key])
                }
            }
            if (params.size) {
                return `${process.env.VUE_APP_API_ROOT}${payload.url}?${params}`
            } else {
                return `${process.env.VUE_APP_API_ROOT}${payload.url}`
            }
        },
        async api_call_wrapper_v2(
            callback,
            {
                error_callback = (e) => {
                    console.error(e)
                },
                use_snackbar = true,
                success_text = 'common.ok',
                error_fallback_text = 'system.error',
            }
        ) {
            let result
            try {
                result = await callback()
                if (use_snackbar) {
                    await this.push_state_property({
                        state_property: 'snackbar_messages',
                        data: {
                            text: success_text,
                        },
                    })
                }
            } catch (error) {
                if (error_callback) {
                    result = await error_callback(error)
                }
                if (use_snackbar) {
                    await this.push_state_property({
                        state_property: 'snackbar_messages',
                        data: {
                            text: this.deep_get(error, 'data._locale_message') || error_fallback_text,
                        },
                    })
                }
            }
            return result
        },
        async api_call_wrapper(callback, error_callback = undefined, use_snackbar = true, success_text = 'common.ok') {
            this.loading = true
            let result = undefined
            try {
                result = await callback()
                if (use_snackbar) {
                    await this.push_state_property({
                        state_property: 'snackbar_messages',
                        data: {
                            text: success_text,
                        },
                    })
                }
            } catch (error) {
                if (error_callback) {
                    result = await error_callback(error)
                }
                if (use_snackbar) {
                    await this.push_state_property({
                        state_property: 'snackbar_messages',
                        data: {
                            text: this.deep_get(error, 'data._locale_message') || 'system.error',
                        },
                    })
                }
            } finally {
                this.loading = false
            }
            return result
        },
        async api_get_all_items(resource, where = {}, projection = {}) {
            let page = 1
            let max_results = 25
            let items = []

            let total = undefined
            let continue_to_fetch = true

            while (continue_to_fetch) {
                let request = await this.api_get({
                    url: `/${resource}`,
                    params: { where, projection, page, max_results },
                })
                if (total === undefined) {
                    total = request.data['_meta']['total']
                }

                items = items.concat(request.data['_items'])
                if (items.length >= total) {
                    continue_to_fetch = false
                } else {
                    page += 1
                }
            }

            return items
        },
        async api_get_first_item(resource, where = {}, sort = {}, projection = {}) {
            let request = await this.api_get({
                url: `/${resource}`,
                params: { where, projection, sort, max_results: 1 },
            })

            let items = request.data['_items']
            if (items.length > 0) {
                return items[0]
            }
            return null
        },
        ...mapActions(['set_state_property', 'push_state_property']),
    },
}
