<template>
    <div class="self-registration__bg d-flex justify-center align-center">
        <div :style="`width: ${responsive_width('', '90vw', '75vw', '50vw', '40vw')}`">
            <component
                v-if="pre_auth_component"
                :is="pre_auth_component"
            />
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'

export default {
    name: 'ThePreAuthentication',
    components: {
        PartnerSelfRegistration: () =>
            import(/*webpackChunkName: "PartnerSelfRegistration"*/ '@/components/partner_self_registration'),
        SignAgreements: () => import(/*webpackChunkName: "MobileBankidSignAgreements"*/ './sign_agreements'),
    },
    mixins: [common_mixin],
    props: {},
    data() {
        return {
            internal_value: undefined,
        }
    },
    computed: {
        pre_auth_component() {
            let custom_access = this.selected_token_json['custom_access']

            if (custom_access === undefined) return false

            return custom_access
                .split('_')
                .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
                .join('')
        },
        ...mapGetters(['selected_token_json']),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val, old_val) {
                if (old_val === undefined) return
                this.$emit('input', val)
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {
        this.internal_value = this.value
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
