<template>
    <v-card
        @click="$emit('sign_in', authenticator['oauth'])"
        class="d-flex justify-center flex-column align-center"
        height="140"
        width="140"
        outlined
    >
        <img
            :alt="authenticator['title']"
            :src="get_asset_url(authenticator['image'])"
        >
        <div
            :style="{ color: $vuetify.theme.currentTheme.secondary }"
            class="text mt-4 font-weight-bold text--secondary"
        >
            {{ translate(authenticator['title']) }}
        </div>
    </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import locale_mixin from '@/mixins/common/locale_mixin'

export default {
    name: 'UserAuthenticatorOauth',
    components: {},
    mixins: [locale_mixin],
    props: {
        authenticator: {
            type: Object,
        },
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters([]),
        ...mapState(['redirect_url_on_login_success']),
    },
    watch: {},
    methods: {
        get_asset_url(asset) {
            return require(`@/assets/${asset}`)
        },
        ...mapActions(['set_state_property']),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
