<template>
    <div
        v-if="show_button"
        :key="partner_account_name"
    >
        <v-hover
            v-slot="{ hover }"
            close-delay="100"
        >
            <v-btn
                @click="redirect_to_tenant_selector"
                class="mr-3 px-2"
                color="white"
                outlined
                small
            >
                <v-icon
                    class="visibility-hidden"
                    left
                    small
                >
                    mdi-pencil
                </v-icon>

                {{ partner_account_name }}

                <v-icon
                    :class="{ 'visibility-hidden': !hover }"
                    right
                    small
                >
                    mdi-pencil
                </v-icon>
            </v-btn>
        </v-hover>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import api_mixin from '@/mixins/common/api_mixin'
import local_storage_mixin from '@/mixins/common/local_storage_mixin'
import common_mixin from '@/mixins/common/mixin'

export default {
    name: 'TheHeaderVismaNetTogglePartnerAccount',
    props: {},
    mixins: [api_mixin, local_storage_mixin, common_mixin],
    components: {},
    data() {
        return {
            partner_account_name: null,
        }
    },
    computed: {
        show_button() {
            return this.partner_account_name && this.multiple_tenants
        },
        multiple_tenants() {
            return Boolean(this.selected_token_json?.vnet_tenant_c > 1)
        },
        ...mapGetters(['selected_token_json']),
        ...mapState(['user_extended_data']),
    },
    watch: {
        'user_extended_data.oauth_type': {
            async handler(val) {
                if (val === 'visma_net') {
                    await this.get_partner_account_name()
                }
            },
            immediate: true,
        },
    },
    methods: {
        async get_partner_account_name() {
            const response = await this.api_get({
                url: `/partner--accounts/${this.user_extended_data.partner_account}`,
                params: {
                    projection: {
                        name: 1,
                    },
                },
            })
            this.partner_account_name = response.data.name
        },
        redirect_to_tenant_selector() {
            const url = `${process.env.VUE_APP_API_ROOT}/authenticates/oauth2/visma_net/${process.env.VUE_APP_NAME}`
            window.location.replace(url)
            this.sign_out()
        },
        ...mapActions(['set_state_properties']),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style lang="sass" scoped>
.visibility-hidden
    visibility: hidden

.v-btn
    font-weight: 500

.v-btn--outlined
    border: 1px solid var(--Color-Border-secondary, #FFFFFF40)
</style>
