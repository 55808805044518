import common_mixin from '@/mixins/common/mixin'
import { mapActions, mapGetters, mapState } from 'vuex'
import { swagger_mixin } from '@/mixins/swagger'

export const item_mixin = {
    mixins: [common_mixin, swagger_mixin],
    data() {
        return {
            resource_schema: null,
            favorites_schema: null,
            settlement_documents: {
                'cases': {
                    reference: 'case',
                    list_reference: 'cases',
                },
                'case--debt-collections': {
                    reference: 'debt_collection',
                    list_reference: 'debt_collections',
                },
                'customer--disputes': {
                    reference: 'dispute',
                    list_reference: 'disputes',
                },
                'case--enforcements': {
                    reference: 'enforcement',
                    list_reference: 'enforcements',
                },
                'customer--amortizations': {
                    reference: 'amortization',
                    list_reference: 'amortizations',
                },
                'debtor--debt-restructurings': {
                    reference: 'debt_restructuring',
                    list_reference: 'debt_restructurings',
                },
                'debtor--debt-surveillances': {
                    reference: 'debt_surveillance',
                    list_reference: 'debt_surveillances',
                },
                'debtor--estates': {
                    reference: 'estate',
                    list_reference: 'estates',
                },
            },
            group_domains: ['debtor--debt-surveillances', 'debtor--estates'],
        }
    },
    computed: {
        resource_title() {
            return this.resource_to_resource_title(this.resource).toLowerCase().replaceAll('-', '_')
        },
        resource_translate_location() {
            // Please do not change this function without consulting the team as we have locales depending on how it currently works.
            return this.resource_title?.toLowerCase()?.replaceAll('-', '_') || ''
        },
        display_favorite_action() {
            return this.resource in (this.favorites_schema?.properties?.favorites?.properties || {})
        },
        favorite_ids() {
            if (!this.user_favorite || !this.user_favorite.favorites || !this.user_favorite.favorites[this.resource]) {
                return []
            }
            return this.user_favorite.favorites[this.resource].map((x) => {
                return x['id']
            })
        },
        translate_locations() {
            return [this.resource_translate_location, 'item']
        },
        item_breadcrumbs_text() {
            let text = ''
            if (this.display_mode === 'edit') {
                text = this.translate('common.edit')
            } else if (this.display_mode === 'create') {
                return this.translate('common.create_new')
            }

            if (text) {
                text = `${text}: `
            }

            const document = this.internal_value || this.resource_document
            const header_attribute = this.resource_item_display?.header?.title?.attribute
            const header_locale = this.resource_item_display?.header?.title?.locale
            if (document && header_attribute) {
                const header_title = this.locale_key(
                    this.deep_get(document, header_attribute),
                    this.translate_locations,
                    true
                )
                text = `${text}${header_title}`
            } else if (header_locale) {
                text = `${text}${this.translate(header_locale)}`
            } else {
                text = `${text}${this.translate('common.item')}`
            }
            return text
        },

        // ========= Access =========
        access_right() {
            return this.resource_access(this.resource)
        },
        allow_create() {
            return this.has_post_access(this.resource)
        },
        allow_edit() {
            return this.has_patch_access(this.resource) || this.has_put_item_access(this.resource)
        },
        allow_delete() {
            return this.has_delete_access(this.resource)
        },

        // ========= UI STRUCTURE EXTRACTION =========
        resource_skip_ui_validation() {
            return this.ui_structure?.[this.resource]?.skip_ui_validation
        },
        resource_search_header() {
            return this.ui_structure?.[this.resource]?.search_header
        },
        resource_skip_translation() {
            return this.ui_structure?.[this.resource]?.skip_translation
        },
        resource_search_result_format() {
            return this.ui_structure?.[this.resource]?.search_result_format
        },
        resource_search_filters() {
            const search_filters = this.ui_structure?.[this.resource]?.search_filters
            const properties = this.resource_schema?.properties

            if (!search_filters || !properties) {
                return null
            }

            return this.key_locale_array(search_filters, this.translate_locations)
                .map((item) => {
                    if (!this.access_to_attribute(this.resource, item.key)) {
                        return null
                    }

                    return Object.assign({}, item, { property: this.get_property(properties, item.key) })
                })
                .filter((item) => !!item)
        },
        resource_text_search() {
            return this.ui_structure?.[this.resource]?.text_search || []
        },
        resource_sort() {
            return this.ui_structure?.[this.resource]?.sort
            // const sort = this.ui_structure?.[this.resource]?.sort
            // if (!sort) {
            //     return null
            // }
            // return this.key_locale_array(sort, this.translate_locations)
        },
        resource_extra_buttons() {
            return this.ui_structure?.[this.resource]?.extra_buttons
        },
        resource_item_search() {
            return this.ui_structure?.[this.resource]?.item_search
        },
        resource_item_search_attributes() {
            return (this.resource_item_search?.attributes || []).filter((x) =>
                this.access_to_attribute(this.resource, x)
            )
        },
        resource_item_display() {
            return this.ui_structure?.[this.resource]?.item_display
        },
        resource_enum() {
            return this.ui_structure?.[this.resource]?.enum
        },
        // END
        ...mapGetters({
            ui_structure: 'ui_structure',
        }),
        ...mapState({
            user_favorite: (state) => state.user_favorite,
            user_extended_data: (state) => state.user_extended_data,
            swagger_paths: (state) => state.swagger.paths,
            resource_titles_in_extended_schemas: (state) => state.swagger.resource_titles_in_extended_schemas,
        }),
    },
    watch: {
        resource: {
            async handler(resource) {
                if (!resource) {
                    return
                }

                const resource_schema = this.get_swagger_schema(resource)
                const favorites_schema = this.get_swagger_schema('user--favorites')
                this.resource_schema = await resource_schema
                this.favorites_schema = await favorites_schema
            },
            immediate: true,
        },
    },
    methods: {
        reference_in_other_resource(resource, in_resource) {
            return this.get_swagger_x_data('x-domain-dependencies')?.[resource]?.[in_resource] || []
        },
        validate(value, property, original_value = '') {
            // TODO: include error handling in rules function in item_display_input
            let status = []

            let skip_required_rule =
                value === null && (property.type === 'string' || property.type === 'number') && !!property.nullable

            if (property?.is_required && !skip_required_rule) {
                status.push(this.required_rule(value))
            }
            if (property?.enum && value === null && original_value) {
                status.push(this.$t(`common.value_not_allowed`))
            }
            if (property?.minLength) {
                status.push(this.min_length_rule(value || '', property.minLength))
            }
            if (property?.maxLength) {
                status.push(this.max_length_rule(value, property.maxLength))
            }
            if (property?.pattern) {
                status.push(this.field_rule(value, property.pattern, property.name))
            }
            if (property?.type === 'number') {
                status.push(this.number_rule(value))
            }
            if (property?.type === 'integer') {
                status.push(this.integer_rule(value))
            }

            let error_messages = status.filter((x) => x !== true)
            if (error_messages.length) {
                return error_messages[0]
            }
            return true
        },
        get_value(item, key, properties) {
            try {
                let value = item
                let keys = key.split('.')
                if (keys.length === 1) {
                    value = value[keys[0]]
                } else {
                    keys.forEach((k) => {
                        value = value[k]
                    })
                }
                let translate = this.is_enum(key, properties)
                value = translate ? this.locale_key(value, [this.resource_translate_location, 'item']) : value
                return value
            } catch (error) {
                return ''
            }
        },

        get_property(properties, key) {
            try {
                let property = properties
                let keys = key.split('.').filter((x) => isNaN(x))
                if (keys.length === 1) {
                    while ('properties' in property || 'items' in property) {
                        property = property['properties'] || property['items']
                    }
                    property = property[keys[0]]
                } else {
                    keys.forEach((k) => {
                        while ('properties' in property || 'items' in property) {
                            property = property['properties'] || property['items']
                        }
                        property = property[k]
                    })
                }

                if (property['$ref']) {
                    property['type'] = '$ref'
                }
                return property
            } catch (error) {
                return ''
            }
        },
        is_enum(key, properties) {
            let property = this.get_property(properties, key)
            return property instanceof Object ? 'enum' in property : false
        },
        is_chip(key) {
            return Boolean(this.resource_enum?.[key])
        },
        get_enum_color(key, value, custom_enum = null) {
            if (typeof value !== 'string') {
                return null
            }

            const attribute = value.split('.').at(-1)

            const enum_dict = custom_enum || this.resource_enum
            return enum_dict?.[key]?.[attribute]?.color
        },
        key_value_is_boolean(key, schema_properties) {
            return this.get_property(schema_properties, key)['type'] === 'boolean'
        },
        format_item_object(obj, properties, copy_obj = true) {
            if (!properties) {
                return obj
            }
            let additional_datetime_properties = ['_updated', '_created']
            let obj_copy = copy_obj ? this.deep_copy(obj) : obj

            for (let key in obj_copy) {
                if (!properties[key] && additional_datetime_properties.indexOf(key) === -1) {
                    continue
                }

                if (typeof obj_copy[key] === 'object' && key in properties) {
                    this.format_item_object(obj_copy[key], properties[key]['properties'], false)
                } else {
                    // let is_format_as_currency_property = this.deep_get(properties, `${key}.description`) === "#format_as_currency"
                    let re = new RegExp('#format_as_currency:?([A-Za-z]*)?')
                    let property_key_description = this.deep_get(properties, `${key}.description`)
                    let is_format_as_currency_property = re.test(property_key_description)
                    let is_private_key_or_date_formatted_property =
                        key.startsWith('_') || this.deep_get(properties, `${key}.format`) === 'date-time'

                    if (typeof obj_copy[key] === 'string' && is_private_key_or_date_formatted_property) {
                        obj_copy[key] = this.format_date_time(obj_copy[key])
                    } else if (typeof obj[key] === 'number' && is_format_as_currency_property) {
                        let currency = re.exec(property_key_description)
                        if (currency || currency[1]) {
                            let custom_currency = currency[1]
                            obj_copy[key] = this.format_as_currency(obj_copy[key], custom_currency)
                        } else {
                            obj_copy[key] = this.format_as_currency(obj_copy[key], this.currency)
                        }
                    }
                }
            }
            return obj_copy
        },
        integer_rule(number) {
            if (!number) {
                return true
            }
            number = String(number)
            return number.indexOf('.') > -1 ? this.$t(`regex.invalid_integer`) : true
        },
        number_rule(number) {
            if (!number) {
                return true
            }
            number = String(number)
            number = number.replace(',', '.')
            let re = new RegExp(/^-?\d*?\.?\d+\.?$/)
            return re.test(number) && number.split('.').length < 3 ? true : this.$t(`regex.invalid_number`)
        },
        required_rule(input) {
            if (input === null || input === undefined || !input.length) {
                return this.$t(`common.required`)
            }
            return true
        },
        min_length_rule(input, min_length) {
            return input?.length < min_length ? this.$t(`common.min_length`, { count: min_length }) : true
        },
        max_length_rule(input, max_length) {
            return input?.length > max_length ? this.$t(`common.max_length`, { count: max_length }) : true
        },
        field_rule(input, pattern, name) {
            let re = new RegExp(pattern)
            return re.test(input) ? true : this.translate(`regex.unmatched_${name}`)
        },
        field_placeholder() {
            if (!this.property.pattern) {
                return ''
            }
            return this.translate(`placeholder.${this.property.name}`)
        },
        field_hint() {
            if (!this.property.pattern) {
                return ''
            }
            return this.translate(`hint.${this.property.name}`)
        },
        resource_to_resource_title(resource) {
            const paths = this.get_swagger_resource_paths(resource)
            return (paths?.resource_methods?.get?.tags?.[0] || '').toLowerCase().replaceAll('-', '_')
        },
        property_reference_to_resource(reference) {
            if (!reference) {
                return undefined
            }

            let resource_parameter = reference.split('/').pop().toLowerCase()
            const resource_parameter_name = resource_parameter.replace('__id', 'Id')
            for (let path in this.swagger_paths) {
                if (path.endsWith(`{${resource_parameter_name}}`)) {
                    return path.split(`{${resource_parameter_name}}`)[0].replaceAll('/', '')
                }
            }
            return undefined
        },
        is_required(property) {
            if (!property) {
                return false
            }
            return (property.is_required || property.dependency_required) && !property.excluded
        },
        where(resource, search_text, search_filter, custom_text_search = null) {
            let where = {
                $and: [],
                $or: [],
            }

            search_filter = structuredClone(search_filter)

            // Search filter should normally be an array according to user settings resource filters. But we handle objects as well
            if (search_filter && !Array.isArray(search_filter)) {
                where = {
                    $and: search_filter['$and'] || [],
                    $or: search_filter['$or'] || [],
                }

                if ('$and' in search_filter) {
                    delete search_filter['$and']
                }
                if ('$or' in search_filter) {
                    delete search_filter['$or']
                }
                search_filter = this.object_to_array_search_filter(search_filter)
            }
            if ((search_text && this.resource_text_search) || custom_text_search) {
                search_text = search_text.trim()
                let regex_search_text = this.regex_string(search_text)
                if (regex_search_text && this.validate_regex(regex_search_text)) {
                    for (let property_path of custom_text_search || this.resource_text_search) {
                        if (this.access_to_attribute(resource, property_path)) {
                            where.$or.push({
                                [property_path]: { $regex: regex_search_text, $options: 'i' },
                            })
                        }
                    }
                }
            }

            if (search_filter) {
                for (let filter of search_filter) {
                    if (Object.keys(filter).length <= 1) {
                        continue
                    }

                    let property_filter = undefined
                    if (filter.value !== undefined) {
                        property_filter = this.format_filter_value(filter.value)
                    } else if (filter.ref) {
                        property_filter = filter.ref
                    } else if (this.$route.path.includes('public-record--swedish-company-registrations')) {
                        // Disable $regex for public-record--swedish-company-registrations
                        property_filter = filter.regex
                    } else if (
                        Object.keys(filter).filter(
                            (x) => ['regex', 'lt', 'lte', 'gt', 'gte', 'in', 'ne', 'exists'].indexOf(x) > -1
                        ).length
                    ) {
                        property_filter = {}
                        if (filter.regex) {
                            let filter_regex = this.regex_string(filter.regex)
                            if (filter_regex && this.validate_regex(filter_regex)) {
                                property_filter.$regex = filter_regex
                                property_filter.$options = 'i'
                            }
                        }

                        if (filter.in) {
                            property_filter.$in = filter.in
                        }

                        for (let key of ['lt', 'lte', 'gt', 'gte', 'ne', 'exists']) {
                            let key_filter = filter[key]

                            if (this.is_object(key_filter)) {
                                let key_filter_value = key_filter['number'] || key_filter['datetime']
                                if (key_filter_value !== undefined) {
                                    property_filter[`$${key}`] = key_filter_value
                                }
                            } else if (key_filter !== undefined && key_filter !== null) {
                                property_filter[`$${key}`] = key_filter
                            }
                        }
                    } else if ('from_number' in filter || 'to_number' in filter) {
                        property_filter = {}
                        if (!isNaN(filter.from_number)) {
                            property_filter['$gte'] = filter.from_number
                        }
                        if (!isNaN(filter.to_number)) {
                            property_filter['$lte'] = filter.to_number
                        }
                    } else if ('from_date' in filter || 'to_date' in filter) {
                        property_filter = {}
                        if (filter.from_date) {
                            property_filter['$gte'] = filter.from_date
                        }
                        if (filter.to_date) {
                            // Next day floored
                            property_filter['$lt'] = this.format_gmt_date(
                                this.add_time(this.format_millis(filter.to_date), {
                                    days: 1,
                                })
                            )
                        }
                    }

                    if (property_filter !== undefined) {
                        where.$and.push({
                            [filter.property]: property_filter,
                        })
                    }
                }
            }

            let keys_to_delete = []
            for (let key of Object.keys(where)) {
                if (!where[key].length) {
                    keys_to_delete.push(key)
                }
            }

            keys_to_delete.forEach((k) => delete where[k])

            return where
        },

        sort(property, order) {
            if (!property) {
                return ''
            }

            let sign = ''
            if (order === 'descending') {
                sign = '-'
            }
            return `${sign}${property}`
        },
        object_to_array_search_filter(object_search_filter) {
            if (!this.exists(object_search_filter)) {
                return []
            }

            return Object.entries(object_search_filter).map(([property, value]) => {
                let new_search_filter_entry = { property }

                if (this.is_object(value)) {
                    for (let [filter_param, filter_value] of Object.entries(value)) {
                        new_search_filter_entry[filter_param.replaceAll('$', '')] = filter_value
                    }
                } else {
                    new_search_filter_entry['value'] = value
                }

                return new_search_filter_entry
            })
        },
        format_filter_value(value) {
            if (value === 'boolean_true') {
                return true
            }
            if (value === 'boolean_false') {
                return false
            }
            return value
        },
        format_property_schema_for_array(property) {
            let formatted_property = {
                description: property.items.description,
                name: property.name,
                pattern: property.pattern,
                type: property.items.type,
                $ref: property.items.$ref,
                access_right: property.access_right,
                properties: property.items.properties,
                excluded: property.excluded,
            }

            if (property.enum) {
                formatted_property['enum'] = property.enum
            }
            if (property.items.enum) {
                formatted_property['enum'] = property.items.enum
            }

            return formatted_property
        },

        async toggle_favorite(item) {
            let index = this.favorite_ids.indexOf(item._id)
            let domain_action = undefined

            let dotted_path = `favorites.${this.resource}`
            let resource_favorites = this.deep_get(this.user_favorite, dotted_path)

            if (index > -1) {
                this.push_state_property({
                    state_property: 'snackbar_messages',
                    data: {
                        text: String.format(
                            this.translate('common.removed_from_watch_list'),
                            this.user_favorite_title(item)
                        ),
                    },
                })
                domain_action = 'favorite_remove'
                resource_favorites.splice(index, 1)
            } else {
                this.push_state_property({
                    state_property: 'snackbar_messages',
                    data: {
                        text: String.format(
                            this.translate('common.added_to_watch_list'),
                            this.user_favorite_title(item)
                        ),
                    },
                })
                domain_action = 'favorite'
                if (!resource_favorites) {
                    resource_favorites = []
                }

                resource_favorites.push({
                    id: item._id,
                    added: new Date().toGMTString(),
                    title: this.user_favorite_title(item),
                })
            }
            await this.update_favorite(dotted_path, resource_favorites)
            await this.user_activity('item', 'domain', this.resource, domain_action)
        },
        user_favorite_title(item) {
            return this.deep_get(item, this.resource_item_search.header)
        },
        async update_favorite(dotted_path, resource_favorites) {
            let user_favorite_copy = JSON.parse(JSON.stringify(this.user_favorite))
            dotted_path = dotted_path.startsWith('favorites') ? dotted_path : `favorites.${dotted_path}`

            try {
                let result = await this.api_patch({
                    url: `/user--favorites/${user_favorite_copy['_id']}`,
                    if_match: user_favorite_copy['_etag'],
                    data: {
                        [dotted_path]: resource_favorites,
                    },
                })

                Object.keys(result.data).forEach((key) => {
                    user_favorite_copy[key] = result.data[key]
                })
                this.deep_set(user_favorite_copy, dotted_path, resource_favorites)
                await this.set_state_property({
                    state_property: 'user_favorite',
                    data: user_favorite_copy,
                })
            } catch (e) {
                await this.get_user_favorite()
                throw e
            }
        },
        apply_model_rules(item, properties, model_rule) {
            if (!model_rule || !item) {
                return
            }
            /*This function requires that the is reset inbetween computations*/
            for (let [prop, prop_rule] of Object.entries(model_rule)) {
                if (prop_rule.properties && properties?.[prop] && item?.[prop]) {
                    let inner_properties =
                        properties[prop].type === 'array'
                            ? properties[prop].items.properties
                            : properties[prop].properties
                    if (inner_properties === undefined) {
                        continue
                    }
                    this.apply_model_rules(item[prop], inner_properties, prop_rule.properties)
                }

                if (item?.[prop] && prop_rule['excludes']) {
                    prop_rule.excludes = Array.isArray(prop_rule.excludes) ? prop_rule.excludes : [prop_rule.excludes]
                    prop_rule.excludes
                        .filter((x) => properties[x])
                        .forEach((exclude_prop) => {
                            properties[exclude_prop]['excluded'] = true

                            /* Update dependencies of exclude property*/
                            if (model_rule[exclude_prop] && model_rule[exclude_prop]['dependencies']) {
                                model_rule[exclude_prop].dependencies = Array.isArray(
                                    model_rule[exclude_prop].dependencies
                                )
                                    ? model_rule[exclude_prop].dependencies
                                    : [model_rule[exclude_prop].dependencies]
                                model_rule[exclude_prop].dependencies
                                    .filter((x) => properties[x])
                                    .forEach((exclude_prop_dependency_prop) => {
                                        properties[exclude_prop_dependency_prop]['excluded'] = true
                                    })
                            }
                        })
                }

                if (prop_rule['dependencies']) {
                    prop_rule.dependencies = Array.isArray(prop_rule.dependencies)
                        ? prop_rule.dependencies
                        : [prop_rule.dependencies]
                    prop_rule.dependencies
                        .filter((x) => properties[x])
                        .forEach((depend_prop) => {
                            if (item[depend_prop] && !properties[prop]['dependency_required']) {
                                properties[prop]['dependency_required'] = true
                            }

                            /*Check chained dependencies if they are excluded and self exclude prop if they exist*/
                            if (model_rule[depend_prop] && model_rule[depend_prop]['excludes']) {
                                model_rule[depend_prop].excludes = Array.isArray(model_rule[depend_prop].excludes)
                                    ? model_rule[depend_prop].excludes
                                    : [model_rule[depend_prop].excludes]
                                let any_excluded_dependencies = false
                                model_rule[depend_prop].excludes
                                    .filter((x) => properties[x])
                                    .forEach((dependency_exclude_prop) => {
                                        if (item[dependency_exclude_prop]) {
                                            any_excluded_dependencies = true
                                        }
                                    })
                                if (any_excluded_dependencies && !properties[prop]['excluded']) {
                                    properties[prop]['excluded'] = true
                                }
                            }
                        })
                }
            }
        },
        apply_access_right(properties) {
            if (!this.access_right) {
                return
            }

            const readonly_refs = this.get_swagger_x_data('x-readonly-refs')?.[this.resource] || []

            for (let p in properties) {
                let property = properties[p]

                let attribute_access =
                    this.access_right && 'attribute_access' in this.access_right
                        ? this.access_right.attribute_access
                        : undefined

                let access = this.access_right['full_attribute_access']
                    ? 'write'
                    : attribute_access
                    ? attribute_access[p] || 'none'
                    : 'none'

                const readonly_property = property['readOnly'] || (property.$ref && readonly_refs.includes(p))
                if (readonly_property || (access === 'write' && !this.allow_edit && this.display_mode !== 'create')) {
                    access = 'read'
                }

                property['access_right'] = access
                let sub_properties =
                    property.type === 'object'
                        ? property.properties
                        : property.type === 'array'
                        ? property.items.properties
                        : undefined
                if (sub_properties) {
                    this.recursively_set_access(sub_properties, access)
                }
            }
        },
        recursively_set_access(sub_properties, access) {
            for (let p in sub_properties) {
                let property = sub_properties[p]

                let property_access = access === 'none' ? 'none' : property['readOnly'] ? 'read' : access
                property['access_right'] = property_access
                let sub_sub_properties =
                    property.type === 'object'
                        ? property.properties
                        : property.type === 'array'
                        ? property.items.properties
                        : undefined
                if (sub_sub_properties) {
                    this.recursively_set_access(sub_sub_properties, property_access)
                }
            }
        },
        apply_required_rules(schema) {
            let properties = schema['properties']
            let required = schema['required'] || []
            if (!properties) {
                return
            }
            Object.keys(properties).forEach((key) => {
                let property = properties[key]
                properties[key]['is_required'] = required.indexOf(key) > -1

                if (property.type === 'array' && property['items'].type === 'object') {
                    this.apply_required_rules(property['items'])
                } else if (property.type === 'object') {
                    this.apply_required_rules(property)
                }
            })
        },
        apply_filter(item, item_resource, properties, attribute_parent_values) {
            const x_domain_dependencies = this.get_swagger_x_data('x-domain-dependencies')

            if (!this.access_right || !['create', 'edit'].includes(this.display_mode)) {
                return
            }

            const method = this.display_mode === 'create' ? 'POST' : this.allow_put(this.resource) ? 'PUT' : 'PATCH'

            // Initialize reference structures
            let reference_items = []
            for (const [key, property] of Object.entries(properties)) {
                if (property.$ref && !key.startsWith('_')) {
                    reference_items.push({
                        key: key,
                        resource: this.property_reference_to_resource(property.$ref),
                        property: property,
                    })
                }
            }

            // Add parent and child references to each reference item
            for (const reference_item of reference_items) {
                const { resource } = reference_item

                let parent_refs = []
                for (const parent_resource in x_domain_dependencies) {
                    const foreign_keys = x_domain_dependencies[parent_resource][resource]
                    if (!foreign_keys) {
                        continue
                    }

                    for (const foreign_key of foreign_keys) {
                        if (reference_items.find((x) => x.key === foreign_key)) {
                            parent_refs.push(foreign_key)
                        }
                    }
                }

                let child_refs = []
                for (const [dependency_resource, foreign_keys] of Object.entries(x_domain_dependencies[resource])) {
                    const dependency_reference_item = reference_items.find((x) => x.resource === dependency_resource)
                    if (!dependency_reference_item) {
                        continue
                    }

                    if (foreign_keys.indexOf(reference_item.key) > -1) {
                        child_refs.push(dependency_reference_item.key)
                    }
                }

                reference_item.parent_references = parent_refs.length ? [...new Set(parent_refs)] : null
                reference_item.child_references = child_refs.length ? [...new Set(child_refs)] : null
            }

            // Add filter to properties
            for (const reference_item of reference_items) {
                const { key, property, parent_references, child_references } = reference_item
                let filter = {}

                if (parent_references) {
                    for (const parent_reference of parent_references) {
                        if (item[parent_reference]) {
                            filter[parent_reference] = item[parent_reference]
                        }
                    }
                }

                if (child_references) {
                    for (const child_reference of child_references) {
                        if (attribute_parent_values[child_reference]?.[key]) {
                            filter['_id'] = attribute_parent_values[child_reference][key]
                        }
                    }
                }

                if (!filter['_id']) {
                    const access_filter = this.filter_for_reference_input(item_resource, method, key)
                    if (access_filter) {
                        filter = { ...filter, ...access_filter }
                    }
                }

                property['filter'] = filter
            }
        },
        get_body_value_data(key_config, item, schema_properties) {
            if (!key_config) {
                return ''
            }

            let { key, value, label } = this.get_key_value(key_config, item, schema_properties)
            let is_boolean = this.key_value_is_boolean(key, schema_properties)

            let is_chip = this.is_chip(key)
            let color = null
            if (is_chip) {
                color = this.get_enum_color(key, value)
            }
            return {
                key,
                value,
                label,
                is_boolean,
                is_chip,
                color,
            }
        },
        validate_attribute_settings(value, dotted_path, item) {
            let settings_condition = {
                exists: function (value, activation_setting_value) {
                    return value && activation_setting_value
                },
                lower_than: function (value, activation_setting_value) {
                    return value < activation_setting_value
                },
                lower_than_equals: function (value, activation_setting_value) {
                    return value <= activation_setting_value
                },
                higher_than: function (value, activation_setting_value) {
                    return value > activation_setting_value
                },
                higher_than_equals: function (value, activation_setting_value) {
                    return value >= activation_setting_value
                },
                equals: function (value, activation_setting_value) {
                    return value === activation_setting_value
                },
            }

            if (!this.resource_attribute_settings?.length) {
                return null
            }

            let attribute_settings = this.resource_attribute_settings.filter((x) => x.property === dotted_path)
            if (!attribute_settings?.length) {
                return null
            }

            for (let x of Object.values(attribute_settings[0].setting)) {
                let condition_fulfilled = true

                let activation_value = x['custom_activation_value']
                    ? this.deep_get(item, x['custom_activation_value'])
                    : value
                const activation_dotted_path = x['custom_activation_value'] || dotted_path

                if (x.activation) {
                    for (let activation_condition of Object.keys(x.activation)) {
                        if (activation_condition === 'exists' && x.activation[activation_condition] === false) {
                            continue
                        }

                        if (!this.resource_schema) {
                            continue
                        }

                        const date_property =
                            this.get_property(this.resource_schema.properties, activation_dotted_path)?.format ===
                                'date-time' &&
                            activation_condition !== 'exists' &&
                            activation_value &&
                            x.activation[activation_condition]
                        if (date_property) {
                            activation_value = new Date(activation_value).getTime()
                            x.activation[activation_condition] = new Date(x.activation[activation_condition]).getTime()
                        }
                        condition_fulfilled &= settings_condition[activation_condition](
                            activation_value,
                            x.activation[activation_condition]
                        )
                    }
                }
                if (condition_fulfilled) {
                    return {
                        name: attribute_settings[0].property,
                        color: x.color,
                        icon: x.icon,
                    }
                }
            }
            return null
        },
        properties_to_headers(property_schema, whitelist = null) {
            const headers = []
            for (const [k, v] of Object.entries(property_schema.properties)) {
                if (whitelist && !whitelist.includes(k)) {
                    continue
                }

                if (v.type === 'object' || v.type === 'array' || !v.type) {
                    continue
                }
                headers.push({
                    text: this.locale_key(k, this.translate_locations, true),
                    value: k,
                    ...this.value_formatting(property_schema, k),
                })
            }
            return headers
        },
        value_formatting(item_schema, attribute_path) {
            // TODO: Should be standardized so the formatting object can be passed around instead of unpacked
            const formatting = {}

            let property = this.get_property(item_schema, attribute_path)

            const translate =
                (property.enum || property.type === 'string') &&
                property?.format !== 'date-time' &&
                !this.resource_skip_translation?.includes(attribute_path)
            if (translate) {
                formatting['translate'] = true
                formatting['locations'] = attribute_path.split('.')
            }

            if (property.type === 'boolean') {
                formatting['is_boolean'] = true
            }

            formatting['is_chip'] = this.is_chip(attribute_path)
            if (property?.description?.includes('#format_as_currency')) {
                formatting['format_as_currency'] = true
                formatting['currency_path'] = this.get_currency_path()
            }
            if (attribute_path === '_created' || attribute_path === '_updated' || property['format'] === 'date-time') {
                formatting['format_as_datetime'] = true
            }
            return formatting
        },
        get_currency_path() {
            let path = this.deep_find_key(this.resource_schema?.['properties'] || {}, 'currency')
            if (!path) return 'currency'

            path = path.replace('.properties', '').replace('.items', '')
            return path ? `${path}.currency` : 'currency'
        },
        go_to_reference(attribute_dotted_path, open_in_new = false) {
            const reference_id = this.deep_get(this.value, attribute_dotted_path)

            const ref = this.get_property(this.resource_schema, attribute_dotted_path).$ref
            console.log('resource_schema', this.resource_schema)
            const reference_resource = this.property_reference_to_resource(ref)

            const route_data = {
                name: 'ItemDisplay',
                params: {
                    resource_id: reference_id,
                    resource: reference_resource,
                },
            }
            if (open_in_new) {
                window.open(this.$router.resolve(route_data).href, '_blank')
            } else {
                this.$router.push(route_data)
            }
        },
        exists_in_after_procedures(origin) {
            return this.internal_after_procedures.map((x) => x.origin).includes(origin)
        },
        get_after_procedure(origin) {
            const index = this.internal_after_procedures.map((x) => x.origin).indexOf(origin)
            if (index <= -1) {
                return null
            }
            return this.internal_after_procedures[index]
        },
        remove_after_procedure(origin) {
            const index = this.internal_after_procedures.map((x) => x.origin).indexOf(origin)
            if (index > -1) {
                this.internal_after_procedures.splice(index, 1)
            }
        },
        async run_after_procedures(resource_id, after_procedures) {
            for (let procedure of this.after_procedures) {
                let success_text = 'common.ok' // every procedure should have a success text

                if (procedure.media_upload) {
                    success_text = 'common.media_file_uploaded'
                    procedure = this.media_file_after_procedure(procedure, resource_id)
                }

                await this.api_call_wrapper_v2(
                    async () => {
                        await this[procedure.type]({
                            url: procedure.url,
                            data: procedure.payload,
                        })
                    },
                    {
                        error_callback: procedure.error_callback,
                        success_text: success_text,
                    }
                )
            }
        },
        media_file_after_procedure(procedure, id) {
            procedure.payload.append('reference_id', id)
            procedure.url = `/media--upload/${id}`
            return procedure
        },
        ...mapActions(['push_state_property']),
    },
}
