import { mapActions, mapGetters, mapState } from 'vuex'

export const swagger_mixin = {
    data() {
        return {}
    },
    mixins: [],
    computed: {
        ...mapGetters([]),
        ...mapState({
            swagger_schemas: (state) => state.swagger.schemas,
            swagger_schemas_in_memory: (state) => state.swagger.schemas_in_memory,
            swagger_paths: (state) => state.swagger.paths,
            swagger_x_data: (state) => state.swagger.x_data,
        }),
    },
    methods: {
        // Getters
        async get_swagger_schema(resource) {
            if (this.swagger_schemas[resource] || this.swagger_schemas_in_memory[resource]) {
                return this.swagger_schemas[resource] || this.swagger_schemas_in_memory[resource]
            }
            return await this._load_swagger_resource_schema(resource)
        },
        get_swagger_resource_paths(resource) {
            // Assumes paths have been loaded (by a watcher in App.vue)
            let swagger_paths = {
                resource_methods: {},
                item_methods: {},
            }

            swagger_paths['resource_methods'] = this.swagger_paths[`/${resource}`] || {}

            let re = `^/${resource}/{['a-zA-Z-]*}$`
            for (let path in this.swagger_paths) {
                let match = path.match(re)
                if (match) {
                    swagger_paths['item_methods'] = this.swagger_paths[path]
                    break
                }
            }

            return swagger_paths
        },
        get_swagger_x_data(x_data_key) {
            // Assumes x_data has been loaded (by a watcher in App.vue)
            return this.swagger_x_data[x_data_key]
        },

        // Loaders
        async load_swagger_paths() {
            const swagger_paths_response = await fetch(`${process.env.VUE_APP_API_ROOT}/swagger-paths`)
            const swagger_paths = await swagger_paths_response.json()
            await this.set_state_property({
                module: 'swagger',
                state_property: 'paths',
                data: swagger_paths,
            })
        },

        async load_swagger_x_data() {
            const x_data_response = await fetch(`${process.env.VUE_APP_API_ROOT}/swagger-x-data`)
            const x_data = await x_data_response.json()
            this.set_state_property({
                module: 'swagger',
                state_property: 'x_data',
                data: x_data,
            })
            return x_data
        },

        async _load_swagger_resource_schema(resource) {
            try {
                const resource_schema_response = await fetch(
                    `${process.env.VUE_APP_API_ROOT}/swagger-definition/${resource}`
                )
                const resource_schema = await resource_schema_response.json()

                const size_of_schema = JSON.stringify(resource_schema).length
                const save_location = size_of_schema < 150000 ? 'schemas' : 'schemas_in_memory'

                this.set_state_property({
                    module: 'swagger',
                    state_property: save_location,
                    key: resource,
                    data: resource_schema,
                })

                return resource_schema
            } catch (e) {
                if (e.response.status === 404) {
                    // Not found
                    return undefined
                } else {
                    throw e
                }
            }
        },

        // Private utility functions

        ...mapActions({
            set_state_property: 'set_state_property',
        }),
    },
}
