export default {
    namespaced: true,
    state: {
        schemas: {},
        schemas_in_memory: {},
        paths: {},
        x_data: {},
    },
    getters: {},
    mutations: {},
    actions: {},
}
