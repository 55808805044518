export default {
    data() {
        return {}
    },
    methods: {
        obj_filter(obj, kwargs) {
            if (!kwargs) return obj

            return Object.keys(obj)
                .filter((key) => {
                    let starts_with = true,
                        ends_with = true,
                        contains = true

                    if ('starts_with' in kwargs) starts_with = key.startsWith(kwargs['starts_with'])
                    if ('ends_with' in kwargs) ends_with = key.endsWith(kwargs['ends_with'])
                    if ('contains' in kwargs) contains = key.indexOf(kwargs['contains']) > -1

                    let match = starts_with && ends_with && contains

                    return 'mode' in kwargs && kwargs['mode'] === 'reverse' ? !match : match
                })
                .reduce((res, key) => ((res[key] = obj[key]), res), {})
        },
        obj_sort(obj) {
            let sorted_obj = {}
            let keys = Object.keys(obj)
            keys.sort((a, b) => {
                if (a < b) return -1
                if (a > b) return 1
                return 0
            }).forEach((k) => {
                sorted_obj[k] = obj[k]
            })
            return sorted_obj
        },
        obj_equals(obj_1, obj_2) {
            return JSON.stringify(this.obj_sort(obj_1)) === JSON.stringify(this.obj_sort(obj_2))
        },
        array_entry_by_translation(array, locations) {
            let translation_by_array_entry = {}
            array.forEach((k) => {
                let original_k = JSON.parse(JSON.stringify(k))
                while (k.startsWith('_')) {
                    k = k.substr(1)
                }
                if (k.indexOf('.') > -1) {
                    k = k.replace('.', '_')
                }

                translation_by_array_entry[this.locale_key(k, locations, true)] = original_k
            })
            return translation_by_array_entry
        },
        reverse_map(map) {
            let reverse_map = {}
            Object.keys(map).forEach((k) => {
                reverse_map[map[k]] = k
            })
            return reverse_map
        },
        truthy(x) {
            return x && typeof x === 'object' ? Object.keys(x).length : x
        },
        exists(x) {
            return this.truthy(x)
        },
        regex_string(string) {
            let special_characters = [
                '.',
                '^',
                '$',
                '*',
                '+',
                '-',
                '?',
                '(',
                ')',
                '[',
                ']',
                '{',
                '}',
                '\\',
                '|',
                '—',
                '/',
            ]

            let indexes_with_special_characters = []
            for (let i = 0; i < string.length; i++) {
                let char = string.at(i)

                if (special_characters.includes(char)) {
                    indexes_with_special_characters.push(i)
                }
            }

            let regex_string = string
            for (let i = indexes_with_special_characters.length; i > 0; i--) {
                let index = indexes_with_special_characters[i - 1]
                regex_string = regex_string.substring(0, index) + '\\' + regex_string.substring(index)
            }
            return regex_string
        },
        validate_regex(regex, options = '') {
            try {
                new RegExp(regex, options)
                return true
            } catch (e) {
                return false
            }
        },
    },
}
