var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{staticClass:"pa-0",attrs:{"color":"primary","app":"","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"width":"100%"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.$emit('open_left_navigation_drawer')}}}):_vm._e(),_c('img',{staticClass:"mx-4 app-object--clickable",attrs:{"id":"help_home","alt":"logo","height":"24","src":require("@/assets/logo_white.png")},on:{"click":_vm.go_to_home,"mouseup":function($event){if('button' in $event && $event.button !== 1)return null;return _vm.open_home_in_new_window.apply(null, arguments)}}}),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-tabs',{staticClass:"ml-2",attrs:{"color":"primary","dark":"","optional":""},model:{value:(_vm.current_tab),callback:function ($$v) {_vm.current_tab=$$v},expression:"current_tab"}},[_c('v-tabs-slider',{attrs:{"color":"brand_primary"}}),_vm._l((_vm.filtered_navigation_menu_without_other),function(menu,index){return _c('v-tab',{key:index,on:{"click":function($event){return _vm.handle_tab_click(menu, index)}}},[_c('v-badge',{attrs:{"content":_vm.badges[index],"value":Boolean(menu.badge && _vm.badges[index]),"color":"brand_primary"}},[_vm._v(" "+_vm._s(_vm.translate(menu.locale_name))+" ")]),(menu.sub_menu)?_c('v-icon',{staticClass:"ml-2",style:({
                            transform: _vm.current_tab === index ? 'rotate(180deg)' : '',
                        }),attrs:{"small":""}},[_vm._v(" mdi-chevron-down ")]):_vm._e()],1)}),_c('TheHeaderOtherMenu',{attrs:{"other_navigation_menu":_vm.other_navigation_menu},on:{"reset_current_tab":function($event){_vm.current_tab = undefined}}})],2):_vm._e(),_c('AppEnvironmentChip',{staticClass:"mr-2"}),(_vm.user_extended_data.oauth_type === 'visma_net')?_c('VismaNetTogglePartnerAccount'):(_vm.multiple_tokens)?_c('TokenListToggle',{staticClass:"mr-2"}):_vm._e(),_c('v-divider',{attrs:{"vertical":""}}),_c('TheHeaderIconBar',{on:{"open_right_navigation_drawer":function($event){return _vm.$emit('open_right_navigation_drawer')},"right_navigation_component":function($event){return _vm.$emit('right_navigation_component', $event)}}})],1)]},proxy:true},(_vm.show_extension)?{key:"extension",fn:function(){return [_c('v-toolbar',{attrs:{"color":"#333333","dense":""}},[_c('v-tabs-items',{ref:"menu",staticStyle:{"background-color":"inherit","width":"100%"},model:{value:(_vm.current_tab),callback:function ($$v) {_vm.current_tab=$$v},expression:"current_tab"}},_vm._l((_vm.filtered_navigation_menu_without_other),function(menu,index){return _c('v-tab-item',{key:index},[_c('v-tabs',{attrs:{"optional":""},model:{value:(_vm.current_sub_tab),callback:function ($$v) {_vm.current_sub_tab=$$v},expression:"current_sub_tab"}},[_c('v-tabs-slider',{attrs:{"color":"brand_primary"}}),_vm._l((menu.sub_menu),function(sub_menu,s_index){return [(sub_menu.list_menu)?_c('v-menu',{key:`${s_index}_menu`,attrs:{"open-on-click":_vm.current_sub_tab === undefined || _vm.current_sub_tab !== s_index,"open-on-hover":_vm.current_sub_tab === s_index,"close-delay":"100","nudge-bottom":"4","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-tab',_vm._g(_vm._b({key:s_index,attrs:{"color":"transparent","label":""}},'v-tab',attrs,false),on),[_vm._v(" "+_vm._s(_vm.translate(sub_menu.locale_name))+" "),_c('v-icon',{staticClass:"ml-2",style:({
                                                transform:
                                                    _vm.current_sub_tab === s_index && _vm.sub_menus[s_index]
                                                        ? 'rotate(180deg)'
                                                        : '',
                                            }),attrs:{"small":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,true),model:{value:(_vm.sub_menus[s_index]),callback:function ($$v) {_vm.$set(_vm.sub_menus, s_index, $$v)},expression:"sub_menus[s_index]"}},[_c('v-list',{attrs:{"color":"#333333","dark":"","dense":""}},_vm._l((sub_menu.list_menu),function(list_menu,l_index){return _c('v-list-item',{key:l_index,attrs:{"to":list_menu}},[_c('v-list-item-title',{staticStyle:{"color":"#fff"}},[_vm._v(" "+_vm._s(_vm.translate(list_menu.locale_name))+" ")])],1)}),1)],1):_c('v-tab',{key:`${s_index}_tab`,attrs:{"to":sub_menu}},[_vm._v(" "+_vm._s(_vm.translate(sub_menu.locale_name))+" ")])]})],2)],1)}),1)],1)]},proxy:true}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }