<template>
    <v-chip
        :color="chip_color"
        :text-color="text_color"
        small
    >
        <v-avatar left>
            <v-icon
                :color="icon_color"
                small
            >
                {{ icon }}
            </v-icon>
        </v-avatar>
        {{ percent_text }}
    </v-chip>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'TheDashboardStatisticsTrendChip',
    props: {
        percent: {
            type: Number,
            default: 0,
        },
    },
    mixins: [],
    components: {},
    data() {
        return {}
    },
    computed: {
        trending() {
            if (this.percent > 0) {
                return 'up'
            } else if (this.percent < 0) {
                return 'down'
            } else {
                return 'neutral'
            }
        },
        text_color() {
            if (this.trending === 'up') {
                return '#2A6447'
            } else if (this.trending === 'down') {
                return '#5D0E07'
            } else {
                return '#666767'
            }
        },
        icon_color() {
            if (this.trending === 'up') {
                return '#5CC98D'
            } else if (this.trending === 'down') {
                return '#EC5F59'
            } else {
                return '#666767'
            }
        },
        chip_color() {
            if (this.trending === 'up') {
                return '#E7F0E6'
            } else if (this.trending === 'down') {
                return '#F4B5B4'
            } else {
                return '#EFEFEF'
            }
        },
        icon() {
            return `mdi-trending-${this.trending}`
        },
        percent_text() {
            return `${this.percent}%`
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style lang="sass" scoped>
.v-chip:before
    background-color: initial
</style>
