<template>
    <div>
        <v-menu offset-y>
            <template #activator="{ on, attrs }">
                <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                >
                    <v-icon>mdi-help-circle</v-icon>
                </v-btn>
            </template>
            <v-list
                color="#333333"
                dark
            >
                <v-list-item @click="open_faq">
                    <v-icon class="mr-2">
                        mdi-help-box
                    </v-icon>
                    {{ translate('common.the_knowledge_bank') }}
                </v-list-item>
                <v-list-item @click="show_dialog = true">
                    <v-icon class="mr-2">
                        mdi-email
                    </v-icon>
                    {{ translate('common.contact_us') }}
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog
            v-model="show_dialog"
            width="630"
        >
            <v-card>
                <div class="text-h5 d-flex justify-space-between align-center pa-4">
                    {{ translate('common.contact_us') }}
                    <v-btn
                        @click="show_dialog = false"
                        icon
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
                <section class="mx-4 mb-4">
                    <div class="font-weight-bold">
                        {{ translate('common.contact_us_headline_01') }}
                    </div>
                    <div>
                        {{ translate('common.contact_us_text_01') }}
                        <a
                            href="https://faq.amili.se/"
                            target="_blank"
                        >
                            https://faq.amili.se/
                        </a>
                    </div>
                </section>
                <section class="mx-4 mb-4">
                    <div class="font-weight-bold">
                        {{ translate('common.contact_us_headline_02') }}
                    </div>
                    <div>
                        {{ translate('common.contact_us_text_02') }}
                        <a
                            href="https://faq.amili.se/skapa-en-ticket"
                            target="_blank"
                        >
                            https://faq.amili.se/skapa-en-ticket
                        </a>
                    </div>
                </section>
                <section class="mx-4 mb-4">
                    <div class="font-weight-bold">
                        {{ translate('common.contact_us_headline_03') }}
                    </div>
                    <div>
                        {{ translate('common.contact_us_text_03') }}
                        <a href="mailto:support@amili.se">support@amili.se</a>
                    </div>
                </section>
                <v-divider />
                <div class="pa-4 d-flex justify-end">
                    <v-btn
                        @click="show_dialog = false"
                        text
                    >
                        {{ translate('common.close') }}
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import locale_mixin from '@/mixins/common/locale_mixin'

export default {
    name: 'TheHeaderIconBarHelpButton',
    props: {},
    mixins: [locale_mixin],
    components: {},
    data() {
        return {
            show_dialog: false,
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        open_faq() {
            window.open('https://faq.amili.se/', '_blank')
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
