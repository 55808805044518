<template>
    <v-menu
        v-model="menu"
        transition="slide-y-transition"
        bottom
        left
        offset-y
    >
        <template #activator="{ on, attrs }">
            <v-btn
                v-on="on"
                v-bind="attrs"
                style="border-color: white"
                dark
                outlined
            >
                <div class="text-left">
                    <div
                        class="font-weight-bold"
                        style="line-height: 14px"
                    >
                        {{ title(selected_token_json) }}
                    </div>
                    <div
                        class="caption"
                        style="line-height: 12px"
                    >
                        {{ subtitle(selected_token_json) }}
                    </div>
                </div>
                <v-icon
                    :style="{
                        transform: menu ? 'rotate(180deg)' : '',
                    }"
                    right
                    small
                >
                    mdi-chevron-down
                </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-list two-line>
                <v-list-item
                    v-for="(item, index) in token_toggle_list"
                    :key="index"
                    :disabled="item.selected"
                    @click="select_token(index)"
                    dense
                >
                    <v-list-item-avatar>
                        <AppAvatar :value="item.subtitle" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.title }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ item.subtitle }}
                        </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action class="caption">
                        <div v-if="!item.selected">
                            <v-icon> mdi-account-switch-outline </v-icon>
                        </div>
                        <div v-else>
                            {{ translate('common.current') }}
                        </div>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
import VueJwtDecode from 'vue-jwt-decode'
import AppAvatar from '@/components/app_avatar/Index.vue'

export default {
    name: 'TokenListToggle',
    props: {
        value: {
            type: [String, Object, Number, Boolean, Array],
        },
    },
    mixins: [common_mixin],
    components: { AppAvatar },
    data() {
        return {
            menu: false,
        }
    },
    computed: {
        token_toggle_list() {
            const token_toggle_list = []

            for (const token_index in this.user_tokens.token_list) {
                const token_json = VueJwtDecode.decode(this.user_tokens.token_list[token_index].token)

                token_toggle_list.push({
                    title: this.title(token_json),
                    subtitle: this.subtitle(token_json),
                    selected: String(token_index) === String(this.user_tokens.selected_token),
                })
            }
            return token_toggle_list
        },
        ...mapGetters(['selected_token_json']),
        ...mapState(['user_tokens']),
    },
    watch: {},
    methods: {
        title(token_json) {
            return token_json.u_name
        },
        subtitle(token_json) {
            if (token_json.system_user) {
                return this.translate('common.system_user')
            } else if (token_json.solicitor) {
                return this.translate('common.solicitor')
            } else {
                return token_json.u_org
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
