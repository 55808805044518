<template>
    <v-card
        class="d-flex flex-column"
        height="500"
        style="grid-area: events"
        outlined
    >
        <div class="pt-6 pb-4 px-6 subtitle-2">
            {{ translate('common.latest_events') }}
        </div>
        <section
            v-if="!items.length"
            class="flex-grow-1 d-flex align-center justify-center"
        >
            <v-progress-circular
                v-if="loading"
                color="brand_primary"
                indeterminate
            />
            <v-fade-transition>
                <div
                    v-show="!loading"
                    class="subtitle-2 font-weight-medium app-opacity--level-5"
                    style="width: 300px"
                >
                    {{ translate('common.latest_events_empty_state') }}
                </div>
            </v-fade-transition>
        </section>
        <section
            v-else
            :class="animation_completed ? 'overflow-auto' : 'overflow-hidden'"
            class="flex-grow-1"
        >
            <TheDashboardEventsListItem
                v-for="(item, index) in items"
                :key="index"
                :index="index"
                :item="item"
                @completed="animation_completed = true"
            />
            <div
                v-if="animation_completed && show_more_available"
                class="px-6 pb-4"
            >
                <div class="d-flex justify-center mt-4">
                    <v-btn
                        :loading="loading"
                        @click="get_data"
                        outlined
                    >
                        {{ translate('common.show_more') }}
                    </v-btn>
                </div>
            </div>
        </section>
    </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { item_mixin } from '@/mixins/item'
import TheDashboardEventsListItem from '@/components/the_dashboard/events/ListItem.vue'

export default {
    name: 'TheDashboardEvents',
    props: {
        creditor_id: {
            type: String,
        },
    },
    mixins: [item_mixin],
    components: { TheDashboardEventsListItem },
    data() {
        return {
            animation_completed: false,
            loading: true,
            items: [],
            meta: {},
        }
    },
    computed: {
        show_more_available() {
            if (!Object.keys(this.meta).length) return false
            return this.meta['max_results'] * this.meta['page'] < this.meta['total']
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        creditor_id() {
            this.items = []
            this.meta = {}
            this.get_data()
        },
    },
    methods: {
        async get_data() {
            this.loading = true

            const page = this.meta.page ? this.meta.page + 1 : 1
            const where = {}
            if (this.creditor_id) {
                where['creditor'] = this.creditor_id
            }

            try {
                const response = await this.api_get({
                    url: '/statistics--creditor-days',
                    params: {
                        page,
                        sort: '-date',
                        where,
                    },
                })

                this.meta = response['data']['_meta']
                this.process_response(response['data']['_items'])
            } finally {
                this.loading = false
            }
        },
        process_response(items) {
            for (const item of items) {
                const dotted_key_paths = ['other.capital_fully_paid']

                for (const key_group of ['start_actions']) {
                    for (const key in item[key_group]) {
                        dotted_key_paths.push(`${key_group}.${key}`)
                    }
                }

                for (const dotted_key_path of dotted_key_paths) {
                    const value = this.deep_get(item, dotted_key_path)

                    if (value) {
                        this.items.push({
                            creditor_name: item['creditor_name'],
                            date: item['date'],
                            key: dotted_key_path.replaceAll('.', '_'),
                            value,
                        })
                    }
                }
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.get_data()
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
