<template>
    <div v-if="environment">
        <AppChip
            :color="environment.color"
            :value="environment.name"
            class="app-text--no-select"
            small
        />
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AppChip from '@/components/app_chip/index.vue'

export default {
    name: 'AppEnvironmentChip',
    props: {},
    mixins: [],
    components: { AppChip },
    data() {
        return {}
    },
    computed: {
        environment() {
            switch (location.host) {
                case 'localhost:8080':
                    return { name: 'LOCALHOST', color: 'orange_main' }
                case 'app-test.amili.se':
                    return { name: 'TEST', color: 'green_main' }
                case 'app-sandbox.amili.se':
                    return { name: 'SANDBOX', color: 'yellow_main' }
                case 'app-stage.amili.se':
                    return { name: 'STAGE', color: 'blue_main' }
                default:
                    return null
            }
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
