<template>
    <v-navigation-drawer
        v-model="internal_value"
        :width="$vuetify.breakpoint.xsOnly ? '90vw' : '550px'"
        @close="internal_value = ''"
        class="rounded-r-xl"
        app
        temporary
    >
        <div class="px-4 py-6 d-flex justify-space-between align-center">
            <img
                @click="go_to('Home')"
                id="help_home"
                class="mx-4 app-object--clickable"
                height="24"
                src="@/assets/logo_black.png"
            />
            <v-btn
                @click="internal_value = false"
                color="primary"
                fab
                icon
                small
            >
                <v-icon> mdi-close</v-icon>
            </v-btn>
        </div>
        <v-divider />
        <div style="height: calc(100% - 89px); overflow-y: auto">
            <BurgerMenu @close="internal_value = false" />
        </div>
    </v-navigation-drawer>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'

export default {
    name: 'TheLeftNavigationDrawer',
    components: {
        BurgerMenu: () => import(/*webpackChunkName: "BurgerMenu"*/ '@/components/burger_menu/index.vue'),
    },
    mixins: [common_mixin],
    props: {
        value: Boolean,
    },
    data() {
        return {
            lazy_value: undefined,
        }
    },
    computed: {
        internal_value: {
            get: function () {
                return this.lazy_value
            },
            set: function (val) {
                this.lazy_value = val
                this.$emit('input', this.lazy_value)
            },
        },
        ...mapGetters(['selected_token_json']),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.lazy_value = val
        },
    },
    methods: {
        update_internal_value(key, value) {
            this.$set(this.internal_value, key, value)
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {
        this.lazy_value = this.value
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
