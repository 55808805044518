import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueTour from 'vue-tour'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faUserSecret, faUser, faCircle, faCheck } from '@fortawesome/free-solid-svg-icons'

import './styles/custom/index.sass'

library.add(fab)
library.add(faUserSecret, faUser, faCircle, faCheck)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)

Vue.config.productionTip = false

require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

if (!String.format) {
    String.format = function (format) {
        let args = Array.prototype.slice.call(arguments, 1)
        return format.replace(/{(\d+)}/g, (match, number) => {
            return typeof args[number] != 'undefined' ? args[number] : match
        })
    }
}

router.beforeEach((to, from, next) => {
    const to_mode = to.query.mode
    const from_mode = from.query.mode
    if (to_mode === from_mode) {
        next()
    } else if (store.state.user_has_unsaved_changes) {
        store.commit('mutate', {
            property: 'unsaved_changes_dialog',
            with: {
                display: true,
                callback: () => next(),
            },
        })
    } else {
        next()
    }
})

// Must be last in main.js
new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app')
