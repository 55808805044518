export default {
    data() {
        return {}
    },
    methods: {
        set_local_storage(key_name, value) {
            return localStorage.setItem(key_name, JSON.stringify(value))
        },
        get_local_storage(key_name) {
            return JSON.parse(localStorage.getItem(key_name))
        },
        get_sub_item(key_name, sub_key) {
            return this.get_local_storage(key_name)[sub_key]
        },
        patch_local_storage(key_name, sub_key, sub_value) {
            let current_storage = this.get_local_storage(key_name)

            if (!current_storage) return false
            current_storage[sub_key] = sub_value
            return this.set_local_storage(key_name, current_storage)
        },

        remove_local_storage(key_name) {
            return localStorage.removeItem(key_name)
        },
        local_storage_size(selected_keys = null) {
            let total_size = 0

            // Iterate over all items in LocalStorage
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i)
                const value = localStorage.getItem(key)
                if (selected_keys && !selected_keys.includes(key)) {
                    continue
                }

                total_size += (key.length + value.length) * 2 // key and value are stored as UTF-16 characters
            }

            // Convert totalSize to kilobytes (KB)
            return total_size / 1024
        },
    },
}
