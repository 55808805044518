<template>
    <section style="grid-area: statistics">
        <v-expand-transition>
            <div
                v-show="show"
                class="text-h5 mb-4"
            >
                {{ translate('common.statistics') }}
            </div>
        </v-expand-transition>
        <section
            class="grid"
            style="gap: 24px"
        >
            <v-card
                v-for="(statistic, key, index) in states"
                :key="index"
                :loading="statistic === null"
                class="pa-6"
                min-height="126"
                outlined
            >
                <template #progress>
                    <v-progress-linear
                        color="brand_primary"
                        absolute
                        indeterminate
                        top
                    />
                </template>
                <div class="subtitle-2 font-weight-medium app-opacity--level-7 app-text--overflow-hidden-ellipsis">
                    {{ translate(`common.${key}`) }}
                </div>
                <v-fade-transition>
                    <section
                        v-show="statistic !== null"
                        class="d-flex align-center flex-wrap mt-5"
                        style="column-gap: 20px; row-gap: 10px"
                    >
                        <div class="count">
                            <div class="number mr-2">
                                {{ new Intl.NumberFormat(current_language).format(statistic) }}
                            </div>
                            <div
                                v-if="current_language === 'sv'"
                                class="st"
                            >
                                st
                            </div>
                        </div>
                        <TheDashboardStatisticsTrend
                            :last_thirty_days="last_thirty_days[key]"
                            :previous_thirty_days="previous_thirty_days[key]"
                        />
                    </section>
                </v-fade-transition>
            </v-card>
        </section>
    </section>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { item_mixin } from '@/mixins/item'
import TheDashboardStatisticsTrend from '@/components/the_dashboard/statistics/trend'

export default {
    name: 'TheDashboardStatistics',
    props: {
        creditor_id: {
            type: String,
        },
        show: {
            type: Boolean,
        },
    },
    mixins: [item_mixin],
    components: {
        TheDashboardStatisticsTrend,
    },
    data() {
        return {
            states: {
                reminder: null,
                debt_collection: null,
                enforcement: null,
                debt_surveillance: null,
                amortization: null,
            },
            last_thirty_days: {},
            previous_thirty_days: {},
        }
    },
    computed: {
        ...mapGetters(['solicitor']),
        ...mapState([]),
    },
    watch: {
        creditor_id() {
            this.get_statistics()
            this.get_trends()
        },
    },
    methods: {
        async get_statistics() {
            this.reset_statistics()

            const where = {
                closed: { $exists: false },
            }
            if (this.creditor_id) {
                where['creditor'] = this.creditor_id
            }
            for (const state in this.states) {
                this.api_get({
                    url: '/cases',
                    params: {
                        where: {
                            state: state,
                            ...where,
                        },
                        projection: {
                            _id: 1,
                        },
                        max_results: 1,
                    },
                }).then((response) => (this.states[state] = response.data['_meta']['total']))
            }
        },
        async get_trends() {
            this.reset_trends()

            let url = null

            if (this.creditor_id) {
                url = '/statistics--creditor-days'
            } else if (this.solicitor) {
                url = null
            } else if (this.has_get_resource_access('statistics--account-days')) {
                url = '/statistics--account-days'
            }

            if (!url) return

            const where = {}
            if (this.creditor_id) {
                where['creditor'] = this.creditor_id
            }

            const promises = [
                ['last_thirty_days', 1],
                ['previous_thirty_days', 2],
            ].map(async ([bucket, page]) => {
                const response = await this.api_get({
                    url,
                    params: {
                        max_results: 30,
                        page,
                        sort: '-date',
                        where,
                    },
                })

                for (const state in this[bucket]) {
                    this[bucket][state] = response['data']['_items'].reduce(
                        (total, item) => total + item['states'][state],
                        0
                    )
                }
            })

            await Promise.all(promises)
        },
        initialize() {
            for (const state in this.states) {
                this.$set(this.last_thirty_days, state, null)
                this.$set(this.previous_thirty_days, state, null)
            }
        },
        reset_statistics() {
            for (const state in this.states) {
                this.states[state] = null
            }
        },
        reset_trends() {
            for (const state in this.states) {
                this.last_thirty_days[state] = null
                this.previous_thirty_days[state] = null
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.initialize()
        this.get_statistics()
        this.get_trends()
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style lang="scss" scoped>
.grid {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    @media only screen and (max-width: 900px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.count {
    display: flex;
    align-items: baseline;

    div.number {
        line-height: 32px;
        font-weight: 500;
        font-size: 32px;
    }

    div.st {
        font-weight: 500;
        font-size: 16px;
    }
}
</style>
