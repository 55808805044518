import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                background: '#FFFFFF',
                bankid: '#183E4F',
                primary: '#0A0D0D',
                brand_primary: '#00E2E2',
                brand_secondary: '#E1FCFF',
                neutral: '#9D9E9E',
                success: '#4CAF50',
                error: '#FF5252',
                warning: '#FB8C00',
                green_dark: '#006644',
                green_main: '#00CC88',
                green_light: '#E5F0EC',
                grey_dark: '#4D4D4D',
                grey_main: '#999999',
                grey_light: '#EFEFEF',
                blue_dark: '#0052CC',
                blue_main: '#3385FF',
                blue_light: '#E5EDFA',
                indigo_dark: '#002B99',
                indigo_main: '#336DFF',
                indigo_light: '#A9BDEF',
                orange_dark: '#8A4600',
                orange_main: '#FF8A11',
                orange_light: '#FFE6CC',
                red_dark: '#D10B23',
                red_main: '#F53D53',
                red_light: '#FEE9EC',
                pink_dark: '#8C0D0D',
                pink_main: '#F05B5B',
                pink_light: '#EDABAB',
                yellow_dark: '#974F0C',
                yellow_main: '#ECC113',
                yellow_light: '#FFF5CC',
                white_dark: '#495057',
                white_main: '#e9ecef',
                white_light: '#f8f9fa',
            },
        },
    },
})
