import Vue from 'vue'
import Vuex from 'vuex'
import VueJwtDecode from 'vue-jwt-decode'
import swagger from '@/store/swagger'
import { differenceInDays } from 'date-fns'
import VuexPersistence from 'vuex-persist'

function restore_state_function(key, storage) {
    let state = storage.getItem(key)
    if (!state || !state.length) return null

    state = JSON.parse(state)

    if (!state.expires || state.expires < Date.now()) {
        console.log('state has expired!!!!')
        return null
    }

    return state.value
}

function save_state_function(key, state, storage, duration_ms) {
    const now = Date.now()
    let expires = null
    let existing_state = storage.getItem(key)
    if (existing_state) {
        existing_state = JSON.parse(existing_state)
        expires = existing_state.expires > now ? existing_state.expires : null
    }

    if (!expires) {
        expires = now + duration_ms
    }

    const state_to_save = {
        value: state,
        expires: expires,
    }
    storage.setItem(key, JSON.stringify(state_to_save))
}

const daily_storage = new VuexPersistence({
    key: 'daily_storage',
    storage: window.localStorage,
    reducer: (state) => ({
        site_refreshes: state.site_refreshes,
        user_extended_data: state.user_extended_data,
        user_favorite: state.user_favorite,

        swagger: {
            schemas: state.swagger.schemas,
            paths: state.swagger.paths,
            x_data: state.swagger.x_data,
        },
    }),
    saveState: (key, state, storage) => {
        const expires = 1000 * 60 * 60 * 24
        return save_state_function(key, state, storage, expires)
    },
    restoreState: restore_state_function,
})

const permanent_storage = new VuexPersistence({
    key: 'permanent_storage',
    storage: window.localStorage,
    reducer: (state) => ({
        application_details: state.application_details,
        redirect_url_on_login_success: state.redirect_url_on_login_success,
        user_login_date: state.user_login_date,
        user_settings_updates: state.user_settings_updates,
    }),
})

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        /**************************************
         Application
         */
        application_details: null,
        user_login_date: null,
        snackbar_messages: [],

        /**************************************
         USER DATA
         */
        user_tokens: null,

        user_extended_data: {},
        user_settings: {},
        user_settings_updates: [
            /*{
                'dotted_path': 'dotted.path.to.change',
                'value': '{the update}'
            }*/
        ],
        user_favorite: {},

        site_refreshes: 0,
        user_has_unsaved_changes: false,
        unsaved_changes_dialog: {
            display: false,
            callback: null,
        },

        /**************************************
         Dashboard data
         */
        dashboard_data: {
            updated: null,
        },

        /**************************************
         Search navigation drawer
         */
        right_navigation_search_init_tab: null,

        /**************************************
         PARTNER DATA
         */
        partner_import_filters: null,
        partner_self_registration_data: null,

        /**************************************
         Login
         */
        redirect_url_on_login_success: null,

        /**************************************
         Token
         */
        token_has_expired: false,
    },
    getters: {
        user_extended_data_loaded: (state) => {
            return state.user_extended_data && Object.keys(state.user_extended_data).length
        },
        access_rights: (state) => {
            return state.user_extended_data ? state.user_extended_data.access_rights : undefined
        },
        selected_token(state) {
            const { token_list, selected_token } = state.user_tokens || {}

            if (!token_list) {
                return null
            }
            return token_list[selected_token || 0]
        },
        selected_token_json: (state) => {
            const { token_list, selected_token } = state.user_tokens || {}

            if (!token_list) {
                return null
            }

            return VueJwtDecode.decode(token_list[selected_token || 0].token)
        },
        system_user: (state) => {
            const { token_list, selected_token } = state.user_tokens || {}

            if (!token_list) {
                return false
            }

            const token_json = VueJwtDecode.decode(token_list[selected_token || 0].token)

            return token_json.system_user
        },
        solicitor: (state) => {
            const { token_list, selected_token } = state.user_tokens || {}

            if (!token_list) {
                return false
            }

            const token_json = VueJwtDecode.decode(token_list[selected_token || 0].token)

            return token_json.solicitor
        },
        token_type: (state) => {
            const { token_list, selected_token } = state.user_tokens || {}

            if (!token_list) {
                return ''
            }

            const token_json = VueJwtDecode.decode(token_list[selected_token || 0].token)

            return token_json.token_type.split('.').pop()
        },
        multiple_tokens: (state) => {
            const { token_list } = state.user_tokens || {}

            return Boolean(token_list?.length > 1)
        },
        user_is_authenticated: (state) => {
            const { token_list } = state.user_tokens || {}

            return Boolean(token_list)
        },
        navigation_menu: (state) => {
            if (!state.user_extended_data || !('ui_structure' in state.user_extended_data)) return []
            return state.user_extended_data['ui_structure']['navigation_menu']
        },
        search_menu: (state) => {
            if (!state.user_extended_data || !('ui_structure' in state.user_extended_data)) return []
            return state.user_extended_data['ui_structure']['search_menu']
        },
        ui_structure: (state) => {
            if (!('ui_structure' in state.user_extended_data)) return {}
            return state.user_extended_data['ui_structure']['structure']
        },
        partner_first_login: (state) => {
            let is_partner = 'partner' in state.user_extended_data
            return is_partner && state.user_extended_data['first_login']
        },
        unfinished_partner_filters: (state) => {
            if (!state.partner_import_filters || state.partner_import_filters._meta.total < 1) return []
            let today = new Date()
            return state.partner_import_filters._items.filter((x) => {
                let day_diff = differenceInDays(today, new Date(x['_created']))
                return !x['is_enabled'] && day_diff < 3
            })
        },
        user_data_loaded: (state) => {
            return Boolean(
                state.user_settings?._created && state.user_favorite?._created && state.user_extended_data?.name
            )
        },
    },
    mutations: {
        mutate(state, payload) {
            if (payload.module) {
                if (payload.key) {
                    state[payload.module][payload.property][payload.key] = payload.with
                } else {
                    state[payload.module][payload.property] = payload.with
                }
            } else if (payload.key) {
                state[payload.property][payload.key] = payload.with
            } else {
                state[payload.property] = payload.with
            }
        },
        push(state, payload) {
            if (payload.module) {
                state[payload.module][payload.property].push(payload.with)
            } else {
                state[payload.property].push(payload.with)
            }
        },
    },
    actions: {
        set_state_property({ commit }, payload) {
            commit('mutate', {
                property: payload.state_property,
                with: payload.data,
                module: payload.module,
                key: payload.key,
            })
        },
        set_state_properties({ commit }, payloads) {
            payloads.forEach((payload) => {
                commit('mutate', {
                    property: payload.state_property,
                    with: payload.data,
                    module: payload.module,
                    key: payload.key,
                })
            })
        },
        push_state_property({ commit }, payload) {
            commit('push', {
                property: payload.state_property,
                with: payload.data,
                module: payload.module,
            })
        },
    },
    modules: {
        swagger: swagger,
    },
    plugins: [
        /* NOTE: Do not use undefined on any properties saved in local storage */
        /* Slow sync of data (Requires site reload) */
        permanent_storage.plugin,
        daily_storage.plugin,
    ],
})
