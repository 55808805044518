<template>
    <AppDialog
        :key="content"
        v-model="display"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        disable_header
        medium
    >
        <template #default>
            <component
                v-model="component_value"
                @close="display = false"
                :is="content"
                allow-close
            />
        </template>
        <template #footer>
            <v-card-actions>
                <v-row
                    justify="end"
                    no-gutters
                >
                    <v-btn @click="display = false">
                        {{ translate('common.ok') }}
                    </v-btn>
                </v-row>
            </v-card-actions>
        </template>
    </AppDialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import common_mixin from '@/mixins/common/mixin'
import AppDialog from '@/components/app_dialog/index.vue'

export default {
    name: 'TheGreetingPopup',
    components: {
        AppDialog,
        AgreementSigning: () => import(/*webpackChunkName: "AgreementSigning"*/ './agreement_signing/Index'),
        PartnerUnfinishedFilters: () =>
            import(/*webpackChunkName: "PartnerUnfinishedFilters"*/ './PartnerUnfinishedFilters'),
        AccessTemplates: () => import(/*webpackChunkName: "AccountingVismaNetToken"*/ './AccessTemplates'),
    },
    mixins: [common_mixin],
    props: {
        value: {
            type: Boolean,
        },
    },
    data() {
        return {
            lazy_value: undefined,
            display: false,
            component_value: undefined,
            title: undefined,
        }
    },
    computed: {
        content() {
            if (this.internal_value) {
                return this.internal_value
            }
            if (!this.truthy(this.user_extended_data)) return undefined

            if (this.display_mobile_bankid_sign_agreements) {
                return 'AgreementSigning'
            }
            if (this.display_partner_first_login_unfinished_filters) return 'PartnerUnfinishedFilters'
            if (this.display_access_templates) return 'AccessTemplates'

            return null
        },
        display_mobile_bankid_sign_agreements() {
            if (!this.user_extended_data.data_to_sign || !this.user_extended_data.data_to_sign.agreements) return false
            return this.user_extended_data.data_to_sign.agreements.length
        },
        display_partner_first_login_unfinished_filters() {
            let has_access =
                this.has_patch_access('partner--import-filters') || this.has_put_item_access('partner--import-filters')
            let unfinished_filters_exist = this.unfinished_partner_filters.length
            return has_access && unfinished_filters_exist && this.partner_first_login && this.site_refreshes < 3
        },
        display_partner_unfinished_filters() {
            return this.unfinished_partner_filters.length
        },
        display_access_templates() {
            return (
                ['invalid'].indexOf(this.deep_get(this.user_extended_data || {}, 'access_template_status.status')) > -1
            )
        },
        internal_value: {
            get: function () {
                return this.lazy_value
            },
            set: function (val) {
                this.lazy_value = val
                this.$emit('input', this.lazy_value)
            },
        },
        ...mapGetters(['partner_first_login', 'unfinished_partner_filters', 'access_rights']),
        ...mapState(['user_extended_data', 'site_refreshes']),
    },
    watch: {
        value(val) {
            this.lazy_value = val
        },
        content(val, old_val) {
            if (val && val.length && val !== old_val) {
                this.display = true
            }
        },
    },
    methods: {
        update_internal_value(key, value) {
            this.$set(this.internal_value, key, value)
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {
        this.lazy_value = this.value
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
