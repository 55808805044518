import { format, isValid } from 'date-fns'

export default {
    data() {
        return {}
    },
    methods: {
        format_gmt_date(milliseconds) {
            return new Date(milliseconds).toGMTString()
        },
        format_date(value) {
            const date = new Date(value)
            return value && isValid(date) ? format(date, 'yyyy-MM-dd') : value
        },
        format_date_time(value) {
            const date = new Date(value)
            return value && isValid(date) ? format(date, 'yyyy-MM-dd HH:mm') : value
        },
        format_millis(value) {
            const date = new Date(value)
            return date.getTime()
        },
        date_diff(date_1, date_2, format = 'hours') {
            const date_diff_config = {
                weeks: 1000 * 60 * 60 * 24 * 7,
                days: 1000 * 60 * 60 * 24,
                hours: 1000 * 60 * 60,
                minutes: 1000 * 60,
                seconds: 1000,
            }

            return (this.format_millis(date_2) - this.format_millis(date_1)) / date_diff_config[format]
        },
        add_time(milliseconds, { days = 0, hours = 0, minutes = 0, seconds = 0 }) {
            return (
                milliseconds +
                days * 24 * 60 * 60 * 1000 +
                hours * 60 * 60 * 1000 +
                minutes * 60 * 1000 +
                seconds * 1000
            )
        },
        floor_to_start_of_day(milliseconds) {
            const date = new Date(milliseconds)
            date.setHours(0, 0, 0, 0)
            return date.getTime()
        },
    },
}
