<template>
    <v-dialog
        v-model="internal_value"
        width="500"
        persistent
    >
        <v-card>
            <div class="text-h5 py-4 px-6 d-flex justify-space-between align-center">
                {{ translate('common.leave_title') }}
                <v-btn
                    @click="internal_value = false"
                    icon
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="body-1 px-6">
                {{ translate('common.leave_text') }}
            </div>
            <v-divider class="mt-4" />
            <div
                class="pa-4 d-flex justify-end"
                style="gap: 16px"
            >
                <v-btn
                    @click="internal_value = false"
                    text
                >
                    {{ translate('common.cancel') }}
                </v-btn>
                <v-btn
                    @click="run_callback"
                    color="primary"
                >
                    {{ translate('common.leave') }}
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import locale_mixin from '@/mixins/common/locale_mixin'

export default {
    name: 'TheUnsavedChangesDialog',
    props: {},
    mixins: [locale_mixin],
    components: {},
    data() {
        return {
            internal_value: false,
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState(['user_has_unsaved_changes', 'unsaved_changes_dialog']),
    },
    watch: {
        unsaved_changes_dialog: {
            handler(data) {
                this.internal_value = data.display
            },
            deep: true,
        },
        user_has_unsaved_changes: {
            handler(value) {
                window.onbeforeunload = () => {
                    return value ? true : null
                }
            },
            immediate: true,
        },
        internal_value(value) {
            if (!value) {
                this.set_state_property({
                    state_property: 'unsaved_changes_dialog',
                    data: {
                        display: false,
                        callback: null,
                    },
                })
            }
        },
    },
    methods: {
        run_callback() {
            this.unsaved_changes_dialog.callback()
            this.internal_value = false
            this.set_state_property({
                state_property: 'user_has_unsaved_changes',
                data: false,
            })
        },
        ...mapActions(['set_state_property']),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
