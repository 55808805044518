<template>
    <v-card
        class="d-flex flex-column"
        height="500"
        style="grid-area: todo"
        outlined
    >
        <section class="pt-6 pb-4 px-6">
            <div class="mb-6 subtitle-2">
                {{ translate('common.todo') }}
            </div>
            <v-chip-group
                v-model="selected_filter"
                mandatory
                show-arrows
            >
                <v-chip
                    v-for="(filter, index) in filters"
                    :key="index"
                    :color="selected_filter === index ? 'brand_primary' : null"
                    :outlined="selected_filter !== index"
                    class="my-0"
                    text-color="primary"
                    label
                >
                    {{ translate(`common.${filter.title}`) }}
                    ({{ filter.count }})
                </v-chip>
            </v-chip-group>
        </section>
        <TheDashboardTodoList
            :key="creditor_id"
            :filters="filters"
            :loading="loading"
            :selected_filter="selected_filter"
            :show_more_available="show_more_available"
            @load_more="get_more_data"
        />
    </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { item_mixin } from '@/mixins/item'
import TheDashboardTodoList from '@/components/the_dashboard/todo/TodoList.vue'

export default {
    name: 'TheDashboardTodo',
    props: {
        creditor_id: {
            type: String,
        },
    },
    mixins: [item_mixin],
    components: { TheDashboardTodoList },
    data() {
        return {
            loading: true,
            selected_filter: 0,
            filters: [
                {
                    title: 'all',
                    data: [],
                    count: 0,
                },
                {
                    title: 'decisions',
                    data: [],
                    method: (x) => this.get_decisions(x),
                    count: 0,
                    meta: {},
                },
                {
                    title: 'tickets',
                    data: [],
                    method: (x) => this.get_tickets(x),
                    count: 0,
                    meta: {},
                },
            ],
        }
    },
    computed: {
        show_more_available() {
            const data_to_compare = []
            if (!this.selected_filter) {
                data_to_compare.push(...this.filters)
            } else {
                data_to_compare.push(this.filters[this.selected_filter])
            }
            return data_to_compare
                .filter((x) => x.meta)
                .map((y) => {
                    return y.meta['max_results'] * y.meta['page'] < y.meta['total']
                })
                .some((z) => z)
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        creditor_id() {
            this.get_data()
        },
    },
    methods: {
        async get_data() {
            this.loading = true
            this.reset()
            for (const filter of this.filters) {
                if (filter.method) {
                    await filter.method(filter)
                    this.filters[0].count += filter.count
                }
            }
            this.loading = false
        },
        reset() {
            for (const filter of this.filters) {
                if (filter['meta']) filter['meta'] = {}
                filter['count'] = 0
                filter['data'] = []
            }
        },
        async get_more_data() {
            this.loading = true
            for (const filter of this.filters) {
                if (filter.meta && filter.method) {
                    const { max_results, page, total } = filter.meta
                    if (max_results * page < total) await filter.method(filter)
                }
            }
            this.loading = false
        },
        async get_decisions(filter) {
            const where = {
                status: 'waiting_on_decision',
            }
            if (this.creditor_id) {
                where['creditor'] = this.creditor_id
            }
            const page = filter.meta.page ? filter.meta.page + 1 : 1
            const response = await this.api_get({
                url: '/decisions',
                params: {
                    where: where,
                    projection: {
                        decision_data: 1,
                        creditor_name: 1,
                        debtor_name: 1,
                        customer_name: 1,
                    },
                    sort: '-_updated',
                    page: page,
                },
            })
            if (response.status === 200) {
                const data = response['data']['_items'].map((v) => {
                    return { ...v, type: 'decision' }
                })
                filter.data.push(...data)
                this.filters[0].data.push(...data)
                filter.count = response['data']['_meta']['total']
                filter.meta = response['data']['_meta']
            }
        },
        async get_tickets(filter) {
            const where = {
                status: 'waiting_on_requester',
                channel: 'amili',
            }
            if (this.creditor_id) {
                where['creditor'] = this.creditor_id
            }
            const page = filter.meta.page ? filter.meta.page + 1 : 1
            const response = await this.api_get({
                url: '/tickets',
                params: {
                    where: where,
                    projection: {
                        subject: 1,
                        creditor_name: 1,
                    },
                    sort: '-_updated',
                    page: page,
                },
            })
            if (response.status === 200) {
                const data = response['data']['_items'].map((v) => {
                    return { ...v, type: 'ticket' }
                })
                filter.data.push(...data)
                this.filters[0].data.push(...data)
                filter.count = response['data']['_meta']['total']
                filter.meta = response['data']['_meta']
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.get_data()
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style lang="scss" scoped>
::v-deep .v-slide-group__content {
    padding: 0;
}

::v-deep .v-slide-item--active {
    border: 1px solid transparent;
}

.v-chip:before {
    background-color: initial;
}
</style>
